.favorite-item {
   overflow: hidden;

   &__block-setting {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
   }

   &__upload-icon {
      margin-left: auto;
      cursor: pointer;

      path {
         transition: 0.2s all;
      }

      &:hover {
         path {
            fill: var(--color-primary-red);
         }
      }
   }

   &__more-icon {
      cursor: pointer;
      padding-left: 14px;
      display: flex;
      align-items: center;
      svg {
         width: 34px;
         path {
            transition: 0.2s all;
         }
      }

      &:hover {
         path {
            fill: var(--color-primary-red);
         }
      }
   }

   &__places-amount {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #3d3a45;
   }

   &__images-list {
      display: grid;
      grid-template-columns: 8fr 4fr;
      grid-template-rows: 1fr 1fr;
      gap: 4px;
      grid-template-areas:
         'f s'
         'f t';

      @media (max-width: 767px) {
         & {
            gap: 8px;
            grid-template-columns: 6fr 6fr;
         }
      }
   }

   &__images-item {
      overflow: hidden;

      &:nth-child(2) {
         border-top-right-radius: var(--app-card-radius);
         grid-area: s;
      }

      &:nth-child(3) {
         border-bottom-right-radius: var(--app-card-radius);
         grid-area: t;
      }

      &:first-child {
         border-bottom-left-radius: var(--app-card-radius);
         border-top-left-radius: var(--app-card-radius);
         grid-area: f;
      }
   }

   &__no-photo {
      position: relative;
      background-color: #f4f2f6;
      padding-top: 50%;
      grid-column: 1/12;
      grid-row: 1/3;
      border-radius: var(--app-card-radius);

      svg {
         position: absolute;
         top: calc(50% - 24px);
         left: calc(50% - 24px);
      }
   }

   &__place {
      font-weight: 600;
      font-size: 21px;
      line-height: 31px;
      color: #2e3034;
      margin-top: 10px;
      white-space: nowrap;
      min-width: 1px;
      text-overflow: ellipsis;
   }

   &__date {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #8c8896;
   }
}
