.hotel-detail-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 81px;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 150;
  border-bottom: 1px solid rgba(197, 198, 207, 0.5);
  transition: 0.3s transform linear;
  transform: translate(0, -100%);

  &--visible {
    transform: translate(0, 0);
  }

  ul {
    list-style: none;
    display: flex;
  }

  li {
    margin-right: 40px;
  }

  &__navigation-button {
    color: #000;
    user-select: none;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price-and-data-content {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      font-weight: 500;
      flex-direction: column;
      color: var(--color-text-gray);
      padding-right: 30px;
    }

    &__price {
      font-size: 11px;
      display: block;
      margin-bottom: 3px;
      white-space: nowrap;
    }

    &__dates {
      white-space: nowrap;
    }

    &__price-value {
      font-size: 15px;
      color: #000;

      font-weight: 500;
    }
  }
}
