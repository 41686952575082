.order-success-payment-data__top-info-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--order-success-blocks-padding);
  margin: 32px 0;

  svg {
    margin-right: 16px;
  }

  &__texts {
    margin-top: -5px;

    b {
      font-size: 21px;
      line-height: 31px;
      font-weight: 600;
    }

    span {
      display: block;
      font-size: 15px;
      font-weight: 400;
      color: rgba(140, 136, 150, 1)
    }
  }
}
