@keyframes actionsDropdownListShow {
  to {
    opacity: 1;
    transform: translate(0, 10px);
  }
}

.actions-dropdown {
  position: relative;
  display: inline-block;

  &__button {
    width: 34px;
    height: 34px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(#8C8896, 0.13);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      opacity: 0.6;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  &__list {
    padding: 8px 0;
    position: absolute;
    transform: translate(0, 5px);
    right: 0;
    list-style: none;
    background: #fff;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0 0 14px rgba(#8C8896, 0.13);
    opacity: 0;
    animation: actionsDropdownListShow 0.3s forwards;
    z-index: 1000;
  }

  &__list-item {
    button {
      display: flex;
      align-items: center;
      height: 42px;
      width: 100%;
      background: transparent;
      cursor: pointer;
      padding: 0 17px;

      i {
        display: flex;
        margin-right: 10px;
      }

      span {
        color: var(--color-text-black);
        font-size: 13px;
        font-weight: normal;
      }

      &:hover {
        background: #F4F2F6;
      }
    }

    .actions-dropdown__list-item-remove-button {
      span {
        color: var(--color-primary-red);
      }
    }
  }
}
