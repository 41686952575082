.model-gallery {
  &__arrow {
    position: fixed;
    opacity: 0.5;
    top: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &--next {
      right: 30px;
    }

    &--prev {
      transform: rotate(-180deg);
      left: 30px;
    }

    &--disabled {
      opacity: 0.3;
      cursor: default;

      &:hover {
        opacity: 0.3;
      }
    }
  }
}
