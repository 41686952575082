//.#E11900

.toast {
  background-color: #030303;
}
.toast-success {
  background-color: #5ECBAA;
}
.toast-error {
  background-color: #E11900;
}
.toast-info {
  background-color: #6D5ECB;
}
.toast-warning {
  background-color: #FFC043;
}

.toast-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.toast-message {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}


#toast-container > div {
  border-radius: 8px;
  box-shadow: none;//0 0 5px rgba(#999999,.5);
  opacity: 1;
  padding: 15px 15px 15px 55px;

  &:hover{
    box-shadow: none;//0 0 5px rgba(#999999,.8) !important;
  }
}