@import "src/common/assets/styles/mixin";

.show-more-button {
  font-size: 17px;
  color: var(--color-primary-red);
  background-color: transparent;
  border: none;
  cursor: pointer;

  @include mobileOnly() {
    font-size: 15px;
  }
}
