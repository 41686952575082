@import "src/common/assets/styles/mixin";
@import "src/common/assets/styles/utils";

@keyframes animation-in {
  0% {
    opacity: 0;
    transform: scale(0.99) translate(0, 3px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
}

.hotel-list-item {
  min-width: 0;
  width: 100%;
  max-width: 302px;

  &--animated {
    animation: animation-in 1s forwards;
  }

  &--selected {
    background: #F4F2F6 !important;
    margin-bottom: 32px;
    padding: 16px;
  }

  &__properties {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-text-gray);

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
  }

  @media all and (max-width: 650px) {
    max-width: 100%;
  }

  @include mobileOnly() {
    max-width: 100%;
    background: #fff;
    border-radius: 8px;
  }

  &__image {
    width: 100%;
    border-radius: 8px;
    background-color: #f4f4f4;

    @extend .no-image;
  }

  &__block-image {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    max-height: 173px;

    @include mobileOnly() {
      max-height: none;
    }

    a {
      display: block;
      color: var(--color-text-black);
    }

    .carousel .slick-slider .slick-dots, .carousel.carousel--secondary .slick-slider .slick-dots {
      bottom: 10px;
    }
  }

  &__top-text {
    position: absolute;
    top: 8px;
    left: 8px;
    color: white;
    background: #6D5ECB;
    padding: 4px 5px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;

    @media (max-width: 710px) {
      & {
        left: 12px;
      }
    }
  }

  &__favorites {
    position: absolute;
    top: 13px;
    right: 12px;
  }

  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: var(--color-text-black);
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;

    & a, a {
      text-decoration: none;
      color: var(--color-text-black) !important;
    }

    & :hover {
      color: var(--app-color-primary) !important;
    }

    @media (max-width: 850px) {
      & {
        grid-template-columns: 12fr;
        text-overflow: clip;
        overflow: auto;
        white-space: normal;
      }
    }
  }

  &__block-price__price-container {
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  &__block-price__price-container-prices {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: 5px;
      }
    }
  }

  &__button {
    align-self: center;
    margin-bottom: 2px;
  }

  &__link {
    cursor: pointer;
    text-decoration: none;
    margin-top: 2px;

    display: flex;
    //align-items: flex-end;
    align-items: center;
  }
}
