.next-trip-item{
  display: grid;
  grid-template-columns: 3fr 9fr;
  padding: 10px;
  position: relative;
  align-items: center;
  &__image{
    box-shadow: 0 6px 16px rgba(140, 136, 150, 0.08);
    border-radius: 4px;
  }
  &__block-information{
    margin-left: 10px;
  }
  &__date{
    font-weight: 500;
    font-size: 13px;
    color: #8C8896;
  }
  &__name{
    font-weight: 500;
    font-size: 17px;
    color: #2F2C37;
    max-width: 194px;
    margin-bottom: 10px;
  }
}