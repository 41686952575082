.no-avatar{
  border-radius: 50%;
  background-color: #988DD9;
  color: white;
  font-weight: 600;
  //font-size: 85%;
  //letter-spacing: 1px;
  //line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}