.profile-user {
   display: grid;
   grid-template-columns: auto 1fr;
   justify-content: flex-start;
   align-items: center;
   gap: 20px;

   .profile-user__edit {
      position: relative;

      svg {
         position: absolute;
         bottom: -12px;
         right: -9px;
      }
   }

   .profile-user__image {
      width: 73px;
      min-width: 73px;
      height: 73px;
      border-radius: 50%;
   }

   .profile-user__text-wrapper {
      max-width: 600px;
      overflow: hidden;
   }

   .profile-user__title {
      display: block;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #2f2c37;
      margin-bottom: 6px;
   }

   .profile-user__description {
      display: block;
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: #3d3a45;

      > div{
         overflow: hidden;
         min-width: 1px;
         text-overflow: ellipsis;
      }

      b {
         font-weight: 600;
      }
   }
}
