
.hotel-people-input {
  position: relative;
  cursor: pointer;


  .hotel-people-input__icon {
    position: absolute;
    right: 10px;
    top: calc(50% - 20px);
    transform-origin: center;
    display: inline-block;
    transition: .3s all;

    &.hotel-people-input__icon--rotate {
      transform: rotate(180deg);
    }
  }

}
