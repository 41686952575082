
.status-order{
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  padding: 4px 10px;
  border-radius: 4px;
  white-space: nowrap;
  color: white;

  &.status-order--cart{
    background: rgba(61, 58, 69, 1);
  }

  &.status-order--paid {
    background: rgba(167, 105, 187, 1);
  }

  &.status-order--partial_paid{
    background: rgba(105, 113, 187, 1);
  }

  &.status-order--new{
    background: rgba(233, 73, 121, 1);
  }

  &.status-order--confirmed {
    background: rgba(0, 135, 90, 1);
  }

  &.status-order--cancelled{
    background: rgba(222, 53, 11, 1);
  }
}