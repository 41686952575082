@import "src/common/assets/styles/mixin";

.field-styled-select {
  .react-dropdown-select {
    border: 1px solid #D1CFD5;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    min-height: 32px;
    min-width: 124px;
    display: flex;
    align-items: center;
    padding: 2px 5px 2px 8px;
    opacity: 1;

    &[aria-expanded="true"] {
      border-color: var(--color-primary-blue);
      outline: none;
      box-shadow: none;

      .react-dropdown-select-dropdown {
        z-index: 101;
      }

      .react-dropdown-select-dropdown-handle {
        svg {
          top: -3px;
          fill: var(--color-text-dark);
        }
      }
    }

    &:hover {
      border-color: var(--color-primary-blue);
    }

    @include mobileOnly() {
      min-height: 40px;
    }
  }

  .react-dropdown-select-dropdown {
    border-radius: 4px;
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
    border: none;

    .react-dropdown-select-item {
      display: flex;
      min-height: 32px;
      align-items: center;
      font-size: 13px;
      color: var(--color-text-dark);

      &.react-dropdown-select-item-selected {
        background-color: transparent;
        font-weight: 500;
      }

      //Todo: temp solution
      &[aria-label='Видалити'] {
        color: var(--color-primary-red-light);
      }
    }
  }

  &__selected-value {
    padding: 2px 22px 2px 6px;
    border-radius: 3px;
    font-size: 13px;
    position: relative;
    white-space: nowrap;
    color: #333333;

    button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 16px;
      padding-top: 1px;
      padding-right: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &.disabled {
    .react-dropdown-select {
      border-color: var(--color-icon-light-gray);
      color: var(--color-icon-light-gray);
    }

    .field-styled-select__placeholder {
      color: var(--color-icon-light-gray);
    }
  }

  &__placeholder {
    font-size: 13px;
    width: 100%;
    padding-left: 7px;
    color: var(--color-text-gray);
  }
}

