.hotel-detail-message {


  width: 100%;
  padding: 16px;
  background-color: rgba(50, 154, 123, 1);
  color: white;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;
  align-items: center;
  font-size: 15px;
  line-height: 22px;
  border-radius: 8px;

  &__close{
    cursor: pointer;
    line-height: 0;
    width: 56px;
    min-width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -16px;
    margin-bottom: -16px;
    margin-top: -16px;
    margin-left: -16px;
  }
}

.hotel-detail-message-wrapper{
  position: fixed;
  z-index: 999;
  left: 0;
  width: 100%;
  bottom: 16px;
  padding: 0 16px;
}