.messages-editable {
  position: relative;

  > button{
    position: absolute;
    right: 10px;
    top: calc(50% - 16px);
    cursor: pointer;
    background: rgba(#fff,0);
    svg{
      transition: .3s all;
    }

    &:hover{
      svg{
        rect{
          fill: var(--color-primary-red)
        }
      }
    }
  }


  textarea {
    outline: none;
    resize: none;
    width: 100%;
    background: #fbfbfb;
    border-radius: var(--app-card-radius);
    border: 1px solid #f4f2f6;
    padding: 13px 16px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
}