.favorites-button {
  border-radius: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.variant-2 {
  transform: scale(2);
}

.favorites-icon {
  position: relative;
  //top: 1px;
}


.star-block {
  display: flex;
  align-items: center;
  font-size: 13px;

  svg {
    margin-right: 5px;
  }
}
