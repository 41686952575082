
.status-reservation{
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: white;
  padding: 0 5px;
  border-radius: 4px;
  white-space: nowrap;
  background: gray;


  &.status-reservation--new{
    background: rgba(233, 73, 121, 1);
  }

  &.status-reservation--guaranteed {
    background: rgba(82, 67, 170, 1);
  }

  &.status-reservation--checked_in{
    background: rgba(58, 125, 180, 1);
  }

  &.status-reservation--checked_out{
    background: rgba(195, 150, 70, 1);
  }

  &.status-reservation--no_show{
    background: rgba(174, 122, 106, 1);
  }

  &.status-reservation--cancelled{
    background: rgba(222, 53, 11, 1);
  }
}