@import "src/common/assets/styles/mixin";

.order-index-page-submit-section {


  @include mobileOnly() {
    padding: 0 0 20px;
  }

  &__order-text {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 24px;
    color: var(--color-text-black);

    > label {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 10px;
      align-items: center;
      cursor: pointer;
    }

    span {
      background: none;
      padding: 0;
      //text-decoration: underline;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__order-button {
    width: 100%;
  }
}
