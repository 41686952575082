@import '../../../../../assets/styles/main';

.field-input {
  position: relative;
  width: 100%;

  input {
    position: relative;
    display: block;
    width: 100%;
    appearance: none;
    height: var(--app-form-height-middle);
    border: 1px solid  #C5C6CF;
    color: black;
    padding-left: 10px;
    padding-right: 35px;
    font-size: 16px;
    font-weight: 600;
    text-overflow: ellipsis;

    &:disabled {
      opacity: 0.8;
    }
  }

  &.isError {
    input {
      border-color: #ff564e;
    }
  }

  &.small {
    input {
      height: var(--app-form-height-small);
    }
  }

  &.middle {
    input {
      height: var(--app-form-height-middle);
    }
  }

  &.large {
    input {
      height: var(--app-form-height-large);
    }
  }
}
