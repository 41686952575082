.mobile-hotel-details-footer {
  padding: 16px 16px calc(env(safe-area-inset-bottom) + 16px);
  //height: calc(env(safe-area-inset-bottom) + 80px);
  min-height: calc(env(safe-area-inset-bottom) + 80px);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid var(--common-borders-color);
  z-index: 102;
  //background: #F4F2F6;
  background-color: #fff;
  border-top: 1px solid #F4F2F6;
  //box-shadow: 0 4px 24px rgba(0, 0, 0, 0.65);
  //border-radius: 12px 12px 0 0;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);

  .app-button {
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    margin-bottom: 15px;
  }
}
