.order-success-payment-data__payment-method-info {
  background: rgba(251, 251, 251, 1);
  padding: var(--order-success-blocks-padding);

  &__collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__collapse-content {
    padding-top: 12px;
  }
}
