@import "../../../../../../assets/styles/mixin";

.review-item {
  position: relative;

  @include mobileOnly() {
    padding-left: 36px;
    margin-bottom: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image {
    width: 56px;
    height: 56px;
    border-radius: 100px;
    background-color: var(--color-image-background);

    @include mobileOnly() {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__personal-info {
    display: flex;
    align-items: center;
  }

  &__block-name-image {
    display: flex;
    align-items: center;
  }

  &__block-name {
    margin-left: 15px;

    @include mobileOnly() {
      margin-left: 0;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: var(--color-text-black);

    @include mobileOnly() {
      font-weight: normal;
    }
  }

  &__date {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: var(--color-text-gray);
    margin-top: 4px;

    @include mobileOnly() {
      font-weight: normal;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: var(--color-text-dark);
    margin-top: 16px;

    @include mobileOnly() {
      margin-top: 8px;
      font-weight: normal;
    }
  }

  .show-more-button {
    margin-top: 10px;
  }
}
