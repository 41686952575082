
.color-white {
  color: var(--app-color-white);
}

.color-primary {
  color: var(--app-color-primary);
}

.text-center {
  text-align: center;
}

.no-image {
  background-image: url('/public/images/backgrounds/no-image.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: #F4F2F6;
}
