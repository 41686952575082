
// component
.text-with-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #2f2c37;

  svg {
    margin-right: 16px;
  }
}

