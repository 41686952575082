.custom-select__menu {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--app-card-radius) !important;
  overflow: hidden;

  .custom-select__menu-list {
    padding: 0;

    .custom-select__option {
      padding: 0 24px;

      &.custom-select__option--is-focused {
        background-color: rgba(109, 94, 203, 0.15);
      }

      &.custom-select__option--is-selected {
        background-color: #f4f2f6;
        border-bottom: none;

        .react-select-option__text {
        }
      }
    }
  }
}

.react-select-option {
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  //padding: 0;
  border-bottom: 1px solid #f4f2f6;
  padding: 15px 0;

  img, svg {
    border-radius: 6px;
    width: 36px;
    height: 36px;
    object-fit: cover;
  }

  .react-select-option__text {
    color: var(--color-text-black);
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;

    b {
      font-weight: 500;
      color: var(--app-color-primary);
    }
  }
}

.hotel-search-form {
  padding: 0 0 30px;

  .react-datepicker {
    display: flex;
  }

  &.hotel-search-form--bordered {
  }

  .hotel-search-form__form {
    display: grid;
    grid-template-columns: 8fr 2fr;

    .hotel-search-form__button {
      width: 100%;
      border: 1px solid var(--app-color-primary);
      border-top-right-radius: var(--app-card-radius);
      border-bottom-right-radius: var(--app-card-radius);

      .app-button {
        background: var(--app-color-primary);
        border-color: var(--app-color-primary);
        transition: none;
      }

      &:hover {
        border-color: var(--color-primary-red-light);
        //transition: 0.2s all;

        .app-button {
          background: var(--color-primary-red-light);
          border-color: var(--color-primary-red-light);
        }
      }

      button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        width: 100%;
      }
    }

    .hotel-search-form__inputs {
      background-color: white;
      display: grid;
      grid-template-columns: minmax(0, 1fr) 2fr 1fr;
      border-top-left-radius: var(--app-card-radius);
      border-bottom-left-radius: var(--app-card-radius);

      line-height: 0;
    }

    input {
      display: block;
      width: 100%;
      border: none;

      &:focus {
        background-color: #f4f2f6;
      }
    }
  }
}

.hotel-search-block {
  position: relative;
  border-left: 1px solid #e9e8e9;
  border-top: 1px solid #e9e8e9;
  border-bottom: 1px solid #e9e8e9;

  &.hotel-search-block--round-left {
    border-top-left-radius: var(--app-card-radius);
    border-bottom-left-radius: var(--app-card-radius);
    //border-left: none;
  }

  &.hotel-search-block--round-right {
  }

  &:first-child {
    //border-left: none;
  }

  .hotel-search-block__label {
    position: absolute;
    top: 13px;
    left: 24px;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    z-index: 2;
    color: #2f2c37;
    pointer-events: none;
  }

  .hotel-search-block__input {
    input {
      &::placeholder {
        font-weight: 400;
      }
    }

    .custom-select {
      // dropdown
      .custom-select__menu {
        width: 130%;
        padding: 0;
        border-radius: 0;
      }
    }

    // react-select
    .custom-select__control {
      border-top-left-radius: var(--app-card-radius);
      border-bottom-left-radius: var(--app-card-radius);
      border-color: white;
      padding: 0;
      border-width: 0;
      margin-left: 0px;
      background-color: #fbfbfb;

      &--is-focused {
        background-color: #f4f2f6;
        box-shadow: none;
      }

      .custom-select__value-container--has-value {
        //padding-top: 28px;
      }

      .custom-select__value-container {
        padding-left: 23px;
        height: 69px;
        width: 100%;

        > div {
          overflow: hidden;
        }

        .custom-select__placeholder {
          top: 70%;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          min-width: 1px;

          font-weight: 400;
        }

        .custom-select__single-value {
          top: 70%;
        }

        .custom-select__input {
          margin-top: 16px;
          //overflow: hidden;
          //max-width: 300px;
          input {

            display: block;
            //width: 100%;
            min-width: 1px;


            font-weight: 600;
          }
        }

        padding-top: 17px;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 25px;
        color: #3d3a45;
      }
    }

    .field-input {
      input {
        padding-top: 28px;
        background-color: #fbfbfb;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 25px;
        color: #3d3a45;
        padding-left: 25px;

        &:focus {
          background-color: #f4f2f6;
        }
      }
    }
  }
}
