

.page-header {
  padding-top: 40px;
  padding-bottom: 40px;

  .page-header__title{
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;

  }
  .page-header__breadcrumbs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }

  .page-header__header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }
  }
}
