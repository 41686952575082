
.sign-in-show{
  color: rgba(140, 136, 150, 1);
  font-size: 13px;
  cursor: pointer;
}

.sign-in {
  display: flex;
  flex-direction: column;

  form{
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;

    .sign-in__submit{
      margin-left: auto;
      margin-top: 50px;
    }
  }

  .sign-in__title{
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 14px;

    @media (max-width: 767px){
      font-weight: 500;
      font-size: 27px;
      line-height: 40px;
      color: #2F2C37;
      //margin-bottom: 40px;
      //text-align: center;
    }

  }


  .sign-in__sign-up-link-mobile{
    display: none;

    @media(max-width: 768px) {
      display: block;
      text-align: center;
      margin-top: 63px;
      margin-bottom: 0;

      a{
        font-weight: 400;
      }
    }
  }

  .sign-in__sign-up-link{

    margin-bottom: 53px;

    //@media(max-width: 768px) {
    //  display: none;
    //}
    //font-weight: 500;
    //font-size: 15px;
    //line-height: 22px;
    //color: #8C8896;
    //
    //a {
    //  text-decoration: none;
    //}
  }
  .sign-in__remainder-link{
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin-top: 10px;
    display: block;
    text-align: right;
    //margin-left: auto;
  }

}