
.profile-contacts-form {

  .profile-contacts-form__phone-button {

    margin-bottom: 36px;

    .profile-contacts-form__resend{
      font-size: 13px;
      line-height: 19px;
      margin-top: -20px;
      margin-bottom: 16px;
      span{
        color:#6D5ECB;
        cursor: pointer;
      }
    }

    .profile-contacts-form__info{
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #3D3A45;
      margin-bottom: 16px;
      margin-top: -25px;

    }
  }
}


