.message-item {
   display: grid;
   grid-template-columns: 44px 1fr;
   grid-gap: 15px;

   .message-item__time {
      display: inline-block;
      margin-left: 5px;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: #8c8896;
   }

   .message-item__text {
   }

   .message-item__image {
      width: 44px;
      height: 44px;
      border-radius: 50%;
   }

   .message-item__full-name {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #2f2c37;
   }

   .message-item__message {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: #3d3a45;
      white-space: pre-wrap;

   }
}
