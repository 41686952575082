.hotel-detail-order-summary {
  background-color: #EFFAF7;
  padding: 16px 16px 20px 16px;
  border-radius: 8px;
  //width: 280px;
  width: 100%;
  top: 100px;
  margin-bottom: 25px;
  overflow: hidden;
  position: sticky;

  &__title {
    font-size: 17px;
    line-height: 31px;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
    color: var(--color-text-black);
  }

  &__info-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    span {
      display: block;
      font-size: 13px;
      padding-left: 8px;
      color: var(--color-text-dark);
      font-weight: 500;
    }

    svg {
      flex: 0 0 19px;
    }
  }
}
