
.avatar-edit-modal {
  width: 607px;
  border-radius: var(--app-card-radius);
}

.avatar-edit-body {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  gap: 28px;
  padding-bottom: 20px;
  padding-top: 5px;

  .avatar-edit-body__avatar {
    margin-left: 14px;

  }

  .avatar-edit-body__info {
    padding-top: 3px;

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #3D3A45;

      &:first-child {
        margin-bottom: 20px;
      }

      //margin-bottom: 20px;
    }
  }

  .avatar-edit-body__upload {
    cursor: pointer;
    margin-top: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #5ECBAA;
    background-color: white;
  }


}
