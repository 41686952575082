@import "../../../../../../../../../assets/styles/mixin";

.no-rooms-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  border-radius: 8px;

  @include mobileOnly() {
    flex-direction: column;
    align-items: normal;

    .app-button {
      width: 100%;
      margin-top: 10px;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 19px;
    color: var(--color-primary-red);
    display: flex;
  }

  &.with-button {
    padding: 16px;
    background: #FBFBFB;

    @include mobileOnly() {
      padding: 0;
      background-color: transparent;
    }
  }


}
