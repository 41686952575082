
.profile-navigation-item {
  padding: 20px;
  cursor: pointer;
  min-height: 96px;
  border-radius: 9px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  background-color: var(--app-color-white);
  width: 100%;


  display: grid;
  height: 100%;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }

  .profile-navigation-item__title {
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #2F2C37;
  }

  .profile-navigation-item__icon {
    margin-right: 10px;
  }

  .profile-navigation-item__icon-right {
    align-content: flex-end;
  }
}

