.hotel-filter-content-block {
  border-bottom: 1px solid #E3E4EC;
  padding-bottom: 20px;
  margin-bottom: 25px;
  position: relative;

  &__title {
    display: block;
    margin-bottom: 16px;
    //padding-bottom: 10px;
    //background: blue;
    color: var(--color-text-black);
    background: #fff;
    z-index: 2;
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    //position: sticky;
    //top: -20px;
  }
}
