.account-orders-detail-main {


   .account-orders-detail-info-list {
      list-style: none;
      margin-bottom: 30px;

      @media (max-width: 767px){
         margin-bottom: 15px;
      }

      .ReactCollapse--collapse {
         transition: height 500ms;
      }

      li {
         border-bottom: 1px solid #f4f2f6;

         &:last-child {
            border-bottom: none;
         }
      }
   }
}

.account-orders-detail-main-line{
   //width: 100%;
   height: 8px;
   margin: 0 -30px;
   background-color: #F4F2F6;
   margin-bottom: 26px;
}




.account-orders-detail-subtitle {
   margin-bottom: 15px;
   font-weight: 600;
   font-size: 21px;
   line-height: 31px;
   color: #2f2c37;
}

.orders-detail-confirm{
   max-width: 450px;

   b{
      font-weight: 500;
   }

   .modal-layout-primary__body{
      text-align: center;
      padding: 40px 50px;
   }
}