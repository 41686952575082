@import "src/common/assets/styles/mixin";

.order-empty-page {
  display: flex;
  flex-direction: column;
  max-width: 480px;

  &__title {
    margin-bottom: 12px;
  }

  &__description-text {
    color: var(--color-text-gray);
  }

  &__image {
    display: flex;
    margin: 50px 0;
    max-width: 400px;
  }

  .app-button {
    margin-top: 50px;
    min-width: 330px;
    max-width: 100%;

    @include mobileOnly() {
      min-width: auto;
    }
  }

}
