.mobile-rooms-info-line-texts {
  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
  }

  .app-text {
    font-size: 15px
  }
}
