@import "src/common/assets/styles/mixin";

.hotels-list-load-more-section {
  display: flex;
  justify-content: center;
  padding-bottom: 42px;

  @include mobileOnly() {
    //padding-bottom: 0;
  }
}
