@keyframes mobileRoomSelectAnimationIn {
  0% {
    opacity: 0;
    transform: translate(0, 100px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.mobile-room-select {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  text-align: center;
  z-index: 10001;

  &__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(#2F2C37, 0.67);
    backdrop-filter: blur(3px);
  }

  &__content {
    margin: var(--app-blocks-padding);
    padding-top: var(--app-blocks-padding);
    margin-top: auto;
    background-color: #fff;
    border-radius: 8px;
    opacity: 0;
    z-index: 2;
    overflow: hidden;
    animation: mobileRoomSelectAnimationIn 0.5s forwards;
  }

  &__content-inner {
    max-height: 60vh;
    overflow: auto;
  }

  &__title {
    width: 100%;
    display: inline-block;
    margin-bottom: 15px;
  }

  &__button {
    height: 44px;
    display: block;
    user-select: none;
    width: 100%;
    text-align: center;
    font-size: 17px;
    line-height: 25px;
    background-color: transparent;
    color: var(--color-text-black);
    margin-bottom: 2px;
    opacity: 0.7;

    &:hover, &:focus, &:active, &.selected {
      opacity: 1;
      background-color: var(--common-borders-color);
    }

    &.remove {
      color: var(--color-primary-red);
    }
  }
}
