.form-buttons{
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  justify-content: flex-end;
  margin-top: 0px;

  @media (max-width: 767px){
    grid-template-columns: 1fr 1fr;
  }


  button{
    @media (max-width: 767px){
      width: 100%;
    }
    width: 140px;
    font-weight: 400;
    min-width: auto;
  }
}