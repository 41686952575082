.order-index-reservation-item {
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 22px;

  &__top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__edit-button {
    font-size: 13px;
    color: var(--color-primary-green);
    background-color: transparent;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__info-text {
    display: flex;

    color: var(--color-text-gray);
  }

  &__room-name {
    font-weight: 600;
    display: flex;
    margin-bottom: 8px;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 15px;
      line-height: 22px;
      font-weight: 600;
    }
  }

  &__input-container {
    padding-top: 16px;
  }

  &__bottom-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .app-text, .price-viewer__price {
      font-size: 15px;
    }

    .price-viewer__price {
      font-weight: 600;
    }
  }

  &__bottom-line__label {
    color: var(--color-text-gray);
    font-size: 15px;
  }
}
