.profile-preview-form {
  list-style: none;
  padding-bottom: 32px;
  border-bottom: 1px solid #DDDDDD;
  overflow: hidden;
  li {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #8C8896;
    text-overflow: ellipsis;
    //display: inline-block;
    overflow: hidden;
    min-width: 1px;
    max-width: 100%;

    address {
      font-style: normal;
    }
  }
}