@import "src/common/assets/styles/mixin";

.hotels-filter-modal.modal__content {
  position: relative;
  width: 100%;

  max-width: 746px;
  //max-width: 100%;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 40px);
}

.hotels-filter-modal {
  position: fixed;

  .modal-layout-primary {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  .modal-layout-primary__body {
    padding: 20px 0;
    overflow-y: auto;
  }

  .modal-layout-primary__footer {
    margin-top: auto;
    height: 80px;
    width: 100%;

    @include mobileOnly() {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      flex: 0 0 70px;

      .hotels-filter__footer {
        width: 100%;
      }

      .app-button {
        width: 100%;
      }
    }
  }

  &__mobile-clear-button {
    color: var(--color-text-gray);
    font-size: 14px;
    letter-spacing: normal;
    padding-right: 0;
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 50;

    @include tabletAndUpper() {
      display: none;
    }

  }

}

.hotels-filter {
  padding: 0 20px;

  & div:last-child {
    //border-bottom: none;
  }

  &__price {
    padding: 4px 0;

    .hotel-filter-content-block__title {
      margin-bottom: 0;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;

    @include mobileOnly() {
      display: block;
    }

    .field-counter {
      button, input {
        height: 28px;
      }
    }
  }

  &__content-row {
    min-height: 44px;
    display: flex;
    align-items: center;
  }

  &__footer {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;


  }

  &__footer-submit {
    color: white !important;
  }

  &__reset-button {
    font-size: 15px;
    line-height: 22px;
    color: var(--color-text-gray);
    cursor: pointer;
    background-color: transparent;
  }

  .filter-checkboxes-section {
    &:last-child {
      .hotel-filter-content-block {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

}
