.spinner-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: grayscale(0.5);
  justify-content: center;
  z-index: 999;

  &--fixed {
    position: fixed;
  }

  &--with-background {
    background: #fff;
  }
}

.spinner {
  border-radius: 50%;
  border: 3px solid;
  border-color: var(--common-borders-color);
  border-right-color: var(--color-primary-green);
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}
