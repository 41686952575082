.registration-hotel-contract{
  background-color: white;
  padding: 55px 65px 55px 55px;
  color: rgba(0, 0, 0, 1);

  h1{
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 16px;
  }

  p{
    margin-bottom: 32px;
  }

  &__section{
    margin-top: 32px;
    font-size: 17px;
    line-height: 32px;

    h5{
      font-size: 17px;
      display: block;
      font-weight: 600;
      margin-bottom: 32px;
    }


  }

  &__button{
    min-width: 327px;
    margin-top: 50px;
  }
}