@import "src/common/assets/styles/mixin";

.not-found-back {
  .not-found-back__text {
  }
}

.not-found-back {
  &__text {
  }
}

.not-found-back {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding: 110px 0;

  @include mobileOnly() {
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding: 0 0 20px 0;

    svg {
      height: 250px;
    }
  }

  &__text {
    @include mobileOnly() {
      order: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    > h1 {
      font-weight: 700;
      font-size: 120px;
      line-height: 148px;
      color: #2F2C37;
      margin-bottom: 46px;

      @include mobileOnly() {
        font-size: 70px;
        line-height: 100px;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    > h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 48px;
      display: flex;
      align-items: center;
      color: #8C8896;
      max-width: 440px;
      margin-bottom: 36px;

      @include mobileOnly() {
        font-size: 25px;
        max-width: 312px;
        line-height: 35px;
        margin: 0 auto 30px;
      }
    }

    > h5 {
      font-weight: 500;
      font-size: 21px;
      line-height: 31px;
      color: #2F2C37;
      margin-bottom: 43px;

      @include mobileOnly() {
        text-align: center;
      }
    }
  }

  &__background {
    @include mobileOnly() {
      order: 1;
    }

    svg {
      width: 100%;
    }
  }
}
