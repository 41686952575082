.profile-page {
  max-width: 705px;



  .profile-password-message{
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #8C8896;
    margin-bottom: 25px;
    max-width: 532px;



    .profile-password-message__phone{
     color: black;
    }
  }

  //.profile-password-message-form{
  //  display: grid;
  //  grid-template-columns: auto auto;
  //  grid-gap: 20px;
  //  justify-content: flex-end;
  //}

}