.breadcrumbs-mobile{
  display: grid;
  grid-template-columns: 18px auto 18px;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  margin-bottom: 22px;

  svg {

  }

  h3{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #2F2C37;
  }
}