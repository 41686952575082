@import '../../../../../assets/styles/main';

.field-control {
  margin-bottom: 50px;

  .field-control__error {
    color: var(--app-input-error-color);
    font-size: 11px;
    line-height: 15px;


    span{
      padding-top: 9px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }
}
