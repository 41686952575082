.conversations {
   list-style: none;

   li {
      margin-bottom: 16px;
      cursor: pointer;

      &.active {
         .message-user-item {
            background: #fbfbfb;
            border: 2px solid #c5c6cf;
         }
      }
   }
}
