%button-default {
  height: var(--app-form-height-small);
  min-height: var(--app-form-height-small);
  background-color: var(--app-button-background-fillable);
  border: var(--app-button-border-fillable);
  color: var(--app-button-color-fillable);

  &:disabled {
    border: var(--app-button-border-fillable-disabled);
    color: var(--app-button-color-fillable-disabled);
    background-color: var(--app-button-background-fillable-disabled);
    cursor: not-allowed;

    &:hover {
      background-color: var(--app-button-background-fillable-disabled);
      border: var(--app-button-border-fillable-disabled);
    }
  }
}

.app-button {
  appearance: none;
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 126px;
  font-size: 15px;
  //font-weight: 500;
  transition: 0.2s background-color, 0.2s border-color;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  letter-spacing: 1px;
  text-decoration: none;
  color: var(--app-color-white);
  border-radius: var(--app-button-border-radius);
  user-select: none;

  &:hover {
    background-color: var(--color-primary-red);
    border-color: var(--color-primary-red);
  }

  @extend %button-default;

  //& a{
  //  text-decoration: none;

  //}

  &.outline {
    border: var(--app-button-border-outline);
    background-color: var(--app-button-background-outline);
    color: var(--app-button-color-outline);

    &:hover {
      background-color: var(--app-button-background-outline);
      border: var(--app-button-border-outline);
      color: var(--app-button-color-outline);
    }

    &:disabled {
      border: var(--app-button-border-outline-disabled);
      color: var(--app-button-color-outline-disabled);
      background-color: var(--app-button-background-outline-disabled);
    }

    .button-preloader__dot {
      background-color: var(--color-primary-green);
    }
  }

  &.outline-primary {
    border: var(--app-button-border-outline);
    border-color: var(--app-color-primary);
    background-color: var(--app-button-background-outline);
    color: var(--app-color-primary);

    &:hover {
    }

    &:disabled {
      border: var(--app-button-border-outline-disabled);
      color: var(--app-button-color-outline-disabled);
      background-color: var(--app-button-background-outline-disabled);
    }

    .button-preloader__dot {
      background-color: var(--color-primary-green);
    }
  }

  &.fillable {
    background-color: var(--app-button-background-fillable);
    border: var(--app-button-border-fillable);
    color: var(--app-button-color-fillable);

    &:hover {
    }

    &:disabled {
      border: var(--app-button-border-fillable-disabled);
      color: var(--app-button-color-fillable-disabled);
      background-color: var(--app-button-background-fillable-disabled);
    }
  }

  &.light {
    background: #f4f2f6;
    color: #2f2c37;
    border: 1px solid #f4f2f6;
  }

  &.primary {
    background-color: var(--app-color-primary);
    border-color: var(--app-color-primary);
    color: white;

    &:disabled {
      border: var(--app-button-border-fillable-disabled);
      color: var(--app-button-color-fillable-disabled);
      background-color: var(--app-button-background-fillable-disabled);
      cursor: not-allowed;
    }
  }

  &.link {
    background-color: var(--app-color-transparent);
    color: var(--color-gray);
    border-color: var(--app-color-transparent);
  }

  &.small {
    height: 32px; //var(--app-form-height-small);
    letter-spacing: normal;
    line-height: 15px;
  }

  &.large {
    height: var(--app-form-height-large);
    min-height: var(--app-form-height-large);
  }

  &.middle {
    height: var(--app-form-height-middle);
    min-height: var(--app-form-height-middle);
  }

  &.big {
    height: var(--app-form-height-big);
    min-height: var(--app-form-height-big);
    font-size: 15px;
  }

  &.full-width {
    width: 100%;
  }

  //svg {
  //  min-width: 18px;
  //}

  &__icon-wrap {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    svg {
      flex: 1;
    }
  }

  &.color-red {
    color: var(--color-primary-red);
    border-color: var(--color-primary-red);

    &:hover {
      background-color: var(--color-primary-red);
      color: #fff;
    }
  }
}

.button-preloader {
  display: flex;

  .button-preloader__dot {
    border-radius: 6px;
    display: block;
    width: 6px;
    height: 6px;
    margin: 2px;
    background-color: white;

    &:first-child {
      animation-name: run-dots;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }

    &:nth-child(2) {
      animation-name: run-dots;
      animation-duration: 1s;
      animation-delay: 0.1s;
      animation-iteration-count: infinite;
    }

    &:last-child {
      animation-name: run-dots;
      animation-duration: 1s;
      animation-delay: 0.2s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes run-dots {
    from {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
