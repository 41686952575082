@import "src/common/assets/styles/mixin";

.modal .modal__content.modal-gallery {
  background: transparent;
}

.modal-gallery-wrapper {
  .modal__background {
    backdrop-filter: none;
    background-color: rgba(#2F2C37, 0.9);
  }
}

.modal-gallery {
  padding: 90px;
  user-select: none;

  &__content {
    position: relative;

    @media all and (max-width: 1100px) {
      img {
        width: 100%;
      }
    }

    img {
      display: block;
    }
  }

  &__desktop-slide {
    display: flex;
    justify-content: center;
    //width: 100%;
    width: calc(100vw - 180px);

    img {
      //width: 100%;
      width: auto;
      max-height: calc(100vh - 120px); //15px modal padding - 90px gallery padding - 15px
    }
  }

  &__counter {
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 17px;
    font-weight: 500;
    top: -40px;
    left: 0;
    right: 0;
    position: absolute;

    @include mobileOnly() {
      position: fixed;
    }
  }

  &__close-button {
    background-color: transparent;
    display: flex;
    align-items: center;
    position: fixed;
    justify-content: center;
    top: 45px;
    right: 45px;
    cursor: pointer;
    z-index: 1001;

    > div {
      display: flex;
      align-items: center;
    }

    &:hover {
      opacity: 0.7;
    }

    span {
      color: #fff;
      font-size: 15px;
    }

    svg {
      position: relative;
      width: 21px;
      height: 21px;
      fill: #fff;
      margin-right: 6px;
    }
  }

  @include mobileOnly() {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__content {
      max-width: 100%;
    }

    &__counter {
      top: 62px;
      right: 32px;
      padding: 8px;
      left: auto;
      background-color: #fff;
      color: var(--color-text-dark);
      font-size: 13px;
      border-radius: 28px;
      min-width: 40px;
      text-align: center;
    }

    &__close-button {
      width: 34px;
      height: 34px;
      background-color: #fff;
      border-radius: 34px;
      justify-content: center;
      align-items: center;
      right: auto;
      left: 32px;
      top: 62px;

      svg {
        width: auto;
        height: auto;
        margin-right: 0;
      }
    }

    .slick-dots {
      //bottom: -20px !important;
    }

    .custom-carousel-out {
      //overflow-x: hidden;
      //overflow-y: visible;
    }
  }
}

