@keyframes rheostatAnimationIn {
  0% {
    max-height: 2px;
  }

  100% {
    max-height: 100%;
  }
}


@keyframes columnAnimation {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.price-rheostat {
  align-items: flex-end;
  display: flex;
  //height: 74px;
  height: 90px;
  margin-top: 5px;

  &__rheostat-column {
    width: calc(100% / 50);
    background-color: var(--color-primary-red);
    margin: 0 1px;
    transition: 0.3s all ease-in-out;
    min-height: 2px;
    max-height: 2px;
    animation: rheostatAnimationIn 0.7s forwards;
    animation-timing-function: ease-in-out;
    //animation-delay: 0.3s;
    position: relative;

    &:hover {
      span {
        opacity: 1;
      }
    }

    &.not-in-range {
      background-color: #E3E4EC;
      //transform: scale(0.8);
      //max-height: 100% !important;
      animation: rheostatAnimationIn 0.1s forwards, columnAnimation 0.3s forwards;
    }

    span {
      position: absolute;
      top: -12px;
      font-size: 9px;
      //z-index: 1000;
      background-color: #fff;
      //border: 1px solid #ccc;
      opacity: 1;
      z-index: 0;

      &:first-child {
        background-color: transparent;
        border: none;
      }

      &:nth-child(2) {
        right: 0;
        opacity: 0.5;
        font-size: 8px;
      }

      &:nth-child(3) {
        top: 10px;
        color: red;
        left: 4px;
      }
    }
  }
}

