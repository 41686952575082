.auth-layout {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  background-color: #c0c0c0;

  .auth-layout__wrapper {
    min-height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(380px, 519px);
    grid-column-gap: 30px;
    justify-content: space-between;
    padding: 80px;

    @media (max-width: 767px) {
      grid-template-columns:1fr;

    }
    @media (max-width: 540px) {
      padding: 0;

    }
  }

  .auth-layout__form {
    background-color: var(--app-color-white);
    width: 100%;
    padding: 40px 53px 64px 53px;



    @media (max-width: 767px) {
      padding: 24px;
      height: 100%;
    }

    .auth-layout-header-mobile {
      display: none;

      .auth-layout-header-mobile__logo{
        text-align: center;

        svg {
          max-width: 161px;

          path {
            fill: #000000;
          }
        }

      }

      @media (max-width: 767px) {
        display: grid;
        align-items: center;
        grid-template-columns: 30px auto;
        margin-bottom: 15px;
        grid-gap: 20px;


        //svg {
        //  path {
        //    fill: #6d5ecb;
        //  }
        //}



      }
    }
  }

  .auth-layout__info {
    height: auto;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    flex-direction: column;


    .auth-layout__header{
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 33px;

      button{
        cursor: pointer;
        appearance: none;
        background-color: transparent;
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}


.auth-layout__title {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 14px;
  color: #2F2C37;

  @media (max-width: 767px){
    font-weight: 500;
    font-size: 27px;
    line-height: 40px;
    color: #2F2C37;
    margin-bottom: 8px;
  }
}

.auth-layout__description{
  margin-bottom: 65px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #8C8896;

  @media (max-width: 767px){
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 63px;
  }

  a {
    text-decoration: none;
  }
}