.selected-list-hotel-item-gallery {
  display: grid;
  grid-gap: 8px;
  min-height: 286px;
  max-height: 300px;
  grid-template-areas:
            "a a b c"
            "a a d e";

  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;

  &.photos-length-1 {
    grid-template-areas: "a a";
  }

  &.photos-length-2 {
    grid-template-areas: "a b";
  }

  &.photos-length-3 {
    grid-template-areas: "a b c";
  }

  &.photos-length-4 {
    grid-template-areas:
            "a a b d"
            "a a c d";
  }

  li {
    height: 100%;
    overflow: hidden;

    .image, img {
      padding-top: 0;
      width: 100%;
      height: 100%;
    }

    &:first-child {
      grid-area: a;
    }

    &:nth-child(2) {
      grid-area: b;
    }

    &:nth-child(3) {
      grid-area: c;
    }

    &:nth-child(4) {
      grid-area: d;
    }

    &:nth-child(5) {
      grid-area: e;
    }
  }
}
