.drop-down {
   position: relative;
   width: auto;

   &.drop-down--opened {
      .drop-down__active {
         color: var(--app-color-primary);

         path{
            stroke:  var(--app-color-primary);
         }
      }

      .drop-down__list {
         display: block;
      }
   }

   .drop-down__active {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: inherit;
      font-weight: inherit;
      font-size: 14px;
      //transition: .3s all;

      &:hover {
      }

      .drop-down__icon {
         width: 20px;
         display: flex;
         justify-content: center;
      }
   }

   .drop-down__list {
      display: none;
      //opacity: .9;
      background-color: var(--app-color-primary);
      z-index: 1000;
      padding: 5px;
      position: absolute;
      top: 30px;
      left: -15px;
      box-shadow: 4px -4px 38px -1px rgba(0, 0, 0, 0.2);
      width: 100px;

      &:after {
         position: absolute;
         top: -15px;
         z-index: 10;
         display: block;
         content: '';
         border: 8px solid transparent;
         border-bottom: 8px solid var(--app-color-primary);
         left: calc(50% - 10px);
      }

      .drop-down__item {
         display: flex;
         padding: 7px 15px;
         cursor: pointer;
         color: var(--app-color-white);
         white-space: nowrap;
         font-weight: bold;
         font-size: 13px;
         opacity: 0.8;
         width: 100%;
         //&:hover{
         //  color: var(--app-color-G400);
         //}
      }
   }

   &--top {
      .drop-down__list {
         bottom: 34px;
         left: -36px;
         top: initial;

         &:after {
            top: initial;
            bottom: -15px;
            border: 8px solid transparent;
            border-top: 8px solid var(--app-color-primary);
         }
      }
   }
}
