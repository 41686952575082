@import "src/common/assets/styles/mixin";

.order-collision-modal {
  .modal-layout-primary {
    min-width: 600px;
    font-size: 15px;
    line-height: 22px;

    @media all and (max-width: 750px) {
      min-width: auto;
    }

    &__body {
      padding: 40px;
      text-align: center;
      color: var(--color-text-black);
    }
  }

  @include mobileOnly() {
    margin: 0 var(--app-blocks-padding) !important;

    .modal-layout-primary__title {
      padding: 0;
    }

    .modal-layout-primary__body {
      padding: var(--app-blocks-padding);
    }


    .app-button {
      &.link {
        padding: 0;
        min-width: 0;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
