.field-radio {
  //height: 55px;
  //min-height: 55px;
  position: relative;
  padding: 0 12px 0 0;
  width: 100%;

  --marker-margin: 36px;

  &__label {
    height: 100%;
    width: 100%;
    min-height: 55px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  input {
    + div {
      display: flex;
      width: 100%;
      position: relative;
      padding-left: var(--marker-margin);
      font-size: 15px;
      user-select: none;
      color: var(--color-text-black);

      &:before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        display: flex;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        border-radius: 50%;
        border: 1px solid var(--color-icon-light-gray);
        transition: 0.2s all linear;
      }
    }

    &:checked {
      + div {
        &:before {
          border-width: 5px;
          border-color: var(--color-primary-blue);
        }
      }
    }
  }

  &.marker-position-right {
    input {
      + div {
        padding-left: 0;
        padding-right: var(--marker-margin);

        &:before {
          left: auto;
          right: 0;
        }
      }
    }
  }
}
