.hotel-people-dropdown {
  z-index: 10;
  position: absolute;
  width: 319px;
  max-width: initial; //unset;//120%;
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  padding: 0 24px 0px 24px;
  margin-top: 10px;
  background-color: var(--app-color-white);

  .hotel-people-dropdown__counters {
    list-style: none;

    .kids-list-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;


      > label {
        color: #3D3A45;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
      }
    }

    li {
      border-bottom: 1px solid #EBECF0;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}


.kids-age-select {
  margin-top: 26px;

  //
  //.mui-form-group{
  //  .bar{
  //    border-bottom: 0.0625rem solid #D1CFD5;
  //  }
  //}


  .kids-age-select__info {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #8C8896;
    padding-bottom: 16px;
    padding-top: 16px;
    //border-bottom: 1px solid #EBECF0;

  }

  > ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 40px;

    li {
      width: 100%;
      list-style: none;
    }
  }


}