.amenities {
  display: flex;
}

.amenities-text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  color: var(--color-text-black);

  &.align-self-center {
    align-self: center;
  }
}

.amenities-icon {
  position: relative;
  top: 2px;
  margin-right: 15px;
}

img.amenities-icon {
  top: 0;
  display: block;
}
