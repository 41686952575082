.icons-debug-screen {
  display: flex;
  flex-wrap: wrap;

  li {
    padding: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    margin: 10px;
  }
}
