@import "src/common/assets/styles/mixin";

.modal {
  .modal__content.reservation-remove-modal {
    @include mobileOnly() {
      width: auto;
      height: auto;
      min-height: 0;
      overflow: auto;
      border-radius: 12px;
    }
  }
}

