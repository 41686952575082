.account-rules {
   max-width: 950px;
   .account-rules__body {
      .account-rules__date {
         color: #8c8896;
         font-weight: 400;
         font-size: 15px;
         line-height: 22px;
         margin-bottom: 40px;
      }

      a {
         color: #5ecbaa;
         font-weight: 500;
      }

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      table,
      code {
         margin-bottom: 20px;
         color: #2f2c37;
         font-weight: 500;
      }

      ul {
         list-style: none;
         margin-bottom: 40px;
         li {
            margin-bottom: 12px;
         }
      }

      p {
         font-weight: 400;
         font-size: 13px;
         line-height: 19px;
      }
   }
}
