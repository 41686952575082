@import "src/common/assets/styles/mixin";


.sense-bank{
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  align-items: center;

  @include mobileOnly() {
    grid-template-columns: auto;
    grid-gap: 8px;
  }

  &__logo{
    svg{
      margin-bottom: 3px;
    }
    div{
      font-weight: 500;
      color: rgba(34, 57, 94, 1);
      font-size: 13px;
    }
  }
  &__text{
    max-width: 335px;
    font-size: 15px;
    line-height: 23px;
  }
}