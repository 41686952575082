@import "src/common/assets/styles/mixin";

.order-page-section-title {
  display: flex;
  color: var(--color-text-black);
  font-size: 21px;
  line-height: 31px;
  font-weight: 600;

  @include mobileOnly() {
    font-size: 17px;
    line-height: 25px;
  }
}
