.order-detail {
  margin-top: 34px;

  &__title {
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
  }

  &__block-hotel-information {
    display: grid;
    grid-template-columns: 3fr 9fr;
    margin-top: 26px;
    margin-bottom: 10px;
  }

  &__image {
    box-shadow: 0 6px 16px rgba(140, 136, 150, 0.08);
    border-radius: 8px;
  }

  &__block-description {
    width: 80%;
    margin-left: 14px;
  }

  &__hotel-name {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #2F2C37;
  }

  &__address {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #8C8896;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__block-count-price {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #2F2C37;
  }

  &__price {
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: right;
    color: var(--color-primary-red);
  }

  &__button-wrapper {
    @media (max-width: 566px) {
      display: flex;
      justify-content: center;
    }
  }

  &__button {
    margin-top: 36px;
    width: 327px;
  }
}
