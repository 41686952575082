@import "src/common/assets/styles/mixin";

.selected-list-hotel-item-container {
  position: relative;

  &__favorite-container {
    position: absolute;
    top: 16px;
    right: 16px;
    //z-index: 1;

    .favorites-button {
      background: transparent;
    }
  }
}

.selected-list-hotel-item {
  padding: 16px;
  background: #F4F2F6;
  margin-bottom: 60px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--app-blocks-padding);
  color: var(--app-color-black);
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__name-container {
    display: flex;
    justify-content: space-between;

    .favorites-button {
      background: transparent;
    }
  }

  &__name {
    display: block;
    padding-right: 30px;
    font-size: 21px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__distance {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--color-text-gray);
    min-height: 30px;
  }

  &__place-name {
    margin-left: 10px;
  }

  &__properties {
    margin-top: 30px;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-text-gray);
  }

  &__bottom-section {
    margin-top: auto;

    &__rating-reviews-texts {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 13px;

      .stars-rating {
        margin-right: 10px;
      }
    }
  }


}
