.profile-form-layout {
   margin-bottom: 40px;

   &:last-child {
      .profile-preview-form {
         border-bottom: none;
      }
   }

   .profile-form-layout__inner {
      // only for ProfilePreviewForm
      ul {
         list-style: none;
         padding-bottom: 40px;

         li {
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #8c8896;
         }
      }
   }

   .profile-form-layout__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      padding-right: 20px;

      @media (max-width: 767px) {
         //margin-bottom: 30px;
      }

      h2 {
         //margin-bottom: 16px;
         font-weight: 600;
         font-size: 24px;
         line-height: 31px;
         color: #2f2c37;

         @media (max-width: 767px) {
            font-weight: 500;
            font-size: 21px;
            line-height: 31px;
         }
      }

      button {
         appearance: none;
         background-color: white;
         font-weight: 400;
         font-size: 17px;
         line-height: 25px;
         color: #2f2c37;
         cursor: pointer;
      }
   }
}
