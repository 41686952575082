.button-secondary {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  min-width: 1px;
  //overflow: hidden;
  position: relative;
  user-select: none;

  &:not(:disabled):hover {
    border-color: #6D7188;

    &__text {
      color: #6D7188;
    }

    svg {
      filter: brightness(0.5);
    }
  }

  &__text {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #6D7188;
    align-self: center;
    margin: 0 0 0 8px;
    text-overflow: ellipsis;
    min-width: 1px;
    overflow: hidden;
  }

  &__badge-content {
    position: absolute;
    color: #fff;
    background-color: var(--color-primary-blue);
    height: 18px;
    min-width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    border: 2px solid #fff;
    right: 0;
    top: 5px;
    transform: translate(50%, 0);
    padding: 0 3px;
    border-radius: 9px;
    font-weight: 500;
  }

  &:disabled {
    .button-secondary__text {
      opacity: 0.5;
    }

    cursor: default;
  }
}

.button-secondary__icon {
  position: relative;
}

.button-secondary__block {
  margin: 0 16px 0 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  min-width: 1px;
}
