@import "src/common/assets/styles/mixin";

.hotel-detail-content-block {
  border-bottom: 1px solid rgba(#C5C6CF, 0.5);
  margin-bottom: 30px;

  &--no-border {
    border-bottom: none;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: var(--color-text-black);
    margin-bottom: 25px;

    @include mobileOnly() {
      font-weight: 500;
      margin-bottom: 16px;
    }
  }

  .show-more-button {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
