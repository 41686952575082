.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: var(--app-index-modal);
  overflow: auto;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media (max-width: 767px) {
    overflow: hidden;
    padding: 0;
  }

  &--disable-full-size-on-modal {
    padding: 15px;

    .modal__content {
      overflow: hidden;

      @media (max-width: 767px) {
        width: 90vw;
      }
    }
  }

  &:not(.modal--disable-full-size-on-modal) {
    .modal__content {
      @media (max-width: 767px) {
        width: 100%;
        border-radius: 0;
        min-height: 100%;
        height: 100%;
        overflow: auto;
        overscroll-behavior: contain;
      }
    }
  }

  &__content {
    position: relative;
    background-color: var(--app-color-white);
    max-width: 100%;
    z-index: 400;
    margin: auto;
    border-radius: 12px;
  }

  &.modal--no-padding {
    padding: 0;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: rgba(#000000, .4);
    //animation: animationInBackground 0.3s forwards;
    backdrop-filter: blur(5px);
    z-index: 3;
  }


  &__arrow {
    position: fixed;
    left: 0;
    top: 10px;
    width: 60px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    z-index: 50;

    &:hover {
      opacity: 0.7;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 10px;
    width: 60px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    z-index: 50;

    &:hover {
      opacity: 0.7;
    }
  }
}
