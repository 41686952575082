@import "src/common/assets/styles/mixin";

.hotel-detail-summary-selected-content {
  display: flex;
  flex-direction: column;
  flex: 1;

  @include mobileOnly() {
    .app-text {
      font-size: 15px;
    }
  }

  &__title {
    font-size: 17px;
    display: block;
    margin-bottom: 13px;
    font-weight: 500;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__item {
    //display: flex;
    margin-bottom: 20px;

    &__top-row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-bottom: 5px;

      @include mobileOnly() {
        padding-bottom: 8px;
      }
    }
  }

  &__remove-button {
    background-color: transparent;
    position: relative;
    top: 1px;
    cursor: pointer;

    svg {
      fill: var(--color-icon-light-gray);
    }

    @include mobileOnly() {
      fill: var(--color-icon-gray);
    }

    &:hover {
      svg {
        fill: red
      }
    }
  }

  &__room-name {
    font-size: 13px;
    color: var(--color-text-black);
    font-weight: 500;

    @include mobileOnly() {
      font-size: 15px;
      line-height: 22px;
    }
  }

  &__price {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    align-items: center;

    @include mobileOnly() {
      padding-top: 4px;
    }
  }

  &__price-room-total {
    font-size: 13px;
    color: #000;
    line-height: 19px;
    font-weight: 500;
    display: flex;
    flex-direction: column;

    @include mobileOnly() {
      font-size: 15px;
    }
  }

  .app-button {
    width: 100%;
  }

  &__buttons {
    margin-top: auto;
  }


  &__notify{
    display: block;
    font-size: 13px;
    line-height: 19px;
    color: var(--color-primary-red);
    margin: 10px 0;
  }
}
