.carousel {
  &.carousel--secondary {
    .slick-slider {
      .slick-dots {
        bottom: 0;

        li {
          height: 15px;
          width: 15px;
          margin: 0;
          padding: 0 5px;

          &.slick-active {
            padding: 0;

            button {
              background-color: var(--app-color-white);
              width: 15px;
              border-radius: 2px;
              height: 4px;
              padding: 0;

            }
          }

          button {
            background: rgba(255, 255, 255, 0.42);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            padding: 0;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  .slick-slider {
    .slick-arrow {
      &:before {
        //display: none;
      }

      &.slick-prev {
        left: -35px;
      }

      &.slick-next {
        right: -35px;
      }

      &.slick-disabled {
        //display: none !important;
      }
    }

    .slick-dots {
      bottom: -46px;

      @media (max-width: 767px){

      bottom: -37px;
      }

      li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px;

        @media (max-width: 767px){

        margin: 0;
        }

        &.slick-active {
          button {
            background-color: #8DD9C2;
          }
        }

        button {
          background: rgba(255, 255, 255, 0.42);
          width: 13px;
          height: 13px;
          border-radius: 50%;


          @media (max-width: 767px){
            width: 11px;
            height: 11px;
          }

          &:before {
            display: none;
          }
        }
      }
    }

  }

}
