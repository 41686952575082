.modal-close-button {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  top: 24px;
  left: 24px;
  background-color: #fff;
  border: none;
  z-index: 100;

}
