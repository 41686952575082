.mobile-rooms-list-footer {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 15px 24px;
  border-top: 1px solid var(--common-borders-color);
  font-size: 15px;
  z-index: 1000;

  .price-viewer {
    margin-bottom: 6px;
    display: block;
  }

  &__summary-modal {
    display: flex;
    flex-direction: column;
    min-height: 95%;

    .mobile-modal-header {
      padding: 24px;
    }

    .modal-mobile__body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .app-button {
      margin-bottom: 15px;
    }
  }

  .price-viewer__price {
    font-size: 15px;
  }

  .price-viewer__additional-text {
    font-weight: 500;
    font-size: 11px;
  }
}

.mobile-rooms-list-footer-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
