.no-found-hotel {
  text-align: center;
  margin: 0 auto;

  svg {
    margin-bottom: 37px;
  }

  h4 {
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    color: #2F2C37;
    margin-bottom: 8px;
    max-width: 327px;
  }

  h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    letter-spacing: 0.32px;
    color: #8C8896;
    max-width: 327px;
  }
}