

.header-menu-item {
  padding: 0 5px 0 20px;
  display: grid;
  grid-template-columns: 22px 1fr 22px;
  gap:16px;
  align-items: center;
  justify-content: space-between;

  .header-menu-item__icon {
    display: flex;
    justify-content: center;

    svg {
      width: 22px;
    }
  }

  span {
    display: inline-block;
    padding: 12px 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #2F2C37;
  }
}