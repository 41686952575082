.last-trips{
  margin-top: 26px;

  h3{
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #2E3034;
    margin-bottom: 16px;
  }

  .last-trips__list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-bottom: 40px;
    list-style: none;

    li{
      a{
        text-decoration: none;
      }
    }

    @media (max-width: 900px){
      grid-template-columns: 1fr 1fr
    }

    @media (max-width: 580px){
      grid-template-columns: 1fr
    }
  }


}