.order {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 69px;
  padding-bottom: 374px;
  @media (max-width: 1131px) {
    grid-template-columns: 12fr;
  }
  &__payment {
    @media (max-width: 1131px) {
      display: none;
    }
  }
}

.order__text {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  margin-top: 34px;
}