.footer-contacts-section {
  border-bottom: 1px solid var(--footer-divider-color);

  a {
    display: flex;
    color: var(--footer-texts-color);
    margin-bottom: 20px;
    align-items: center;

    &:hover {
      color: var(--app-color-primary);

      svg {
        stroke-opacity: 1;
        stroke: var(--app-color-primary);
      }
    }

    i {
      display: flex;
      margin-right: 10px;
    }

    span {
      //opacity: 0.6;
      font-size: 13px;
      font-weight: 500;
    }

    svg {
      stroke: var(--footer-site-logo-stroke);
    }
  }
}
