.footer-sudtirol{
  padding: 80px 0;
  background: var(--footer-background);
  position: relative;

  color: var(--footer-texts-color);
  font-size: 13px;
  z-index: 101;


  @media (max-width: 768px) {
    padding: 80px 30px;
  }

  &__wrapper{
    display: grid;
    grid-template-columns: auto 266px;
    grid-gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: auto;
      grid-gap: 64px;
    }
  }

  //&__icons{
  //  justify-content: flex-start;
  //  margin-top: 87px;
  //  display: grid;
  //  grid-template-columns: auto auto;
  //  align-items: center;
  //  grid-gap: 10px;
  //
  //  @media (max-width: 768px) {
  //    margin-top: 36px;
  //  }
  //}

  &__mail{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 10px;
    margin-top: 5px;
    margin-bottom: 43px;

    @media (max-width: 768px) {
      justify-content: flex-start;
      margin-bottom: 28px;
    }

    a{
      color: inherit;
    }
    //color: rgba(196, 194, 215, 1);
  }

  &__buttons{
    display: grid;
    grid-gap: 8px;
  }

  &__right{
    text-align: right;
    @media (max-width: 768px) {
      text-align: left;
      order: -1;
    }
  }

  &:not(.footer-sudtirol-light){
    .outline-primary{
      border: 1px solid rgba(255, 255, 255, 0.13);
      color: white;
    }
  }


  &.footer-sudtirol-light{
    .icon-visa{
      path{
        fill: var(--layout-backbround);
      }
    }
  }
}