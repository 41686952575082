.header {
  width: 100%;
  background-color: var(--app-color-white);
  transition: 0.2s all linear;

  a {
    color: #2F2C37;
  }

  .header__logo-description{
    font-size: 13px;
    white-space: nowrap;
   color: black;
    opacity: 0.6;
  }

  &--index-variant {
    //background-color: #2B307C;
    background: transparent;
    transition: 0.2s all linear !important;
    color: white;

    a {
      color: var(--app-color-white);
    }

    .header__logo {


      path {
        &:not(.no-color){
          fill: white;
        }
      }
    }

    .header__sudtirol-logo {
      path {
        &:not(.no-color){
          fill: white;
        }
      }
    }

    .header__logo-description{
      color: #F4F2F6;
    }
  }

  .header__wrapper {
    display: grid;
    height: 106px;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: space-between;
    align-items: center;

    a {
      display: inline-flex;
    }
  }

  .header__center {
    min-width: 1px;
    display: flex;
    justify-content: center;

    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 27px;
      line-height: 40px;
      user-select: none;
      cursor: pointer;
    }
  }

  ul {
    list-style: none;
  }

  .header__language {

  }

  .header__right {
    display: grid;
    padding-left: 20px;
    grid-template-columns: auto auto;
    grid-gap: 38px;
    align-items: center;
    justify-self: flex-end;
  }
}

.header-reservation-info {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  min-width: 1px;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
