.auth-stepper-with-logo{
  //display: flex;
  //justify-items: center;
  //align-items: center;
  //flex-direction: column;

  //padding: 0 20px 30px 20px;

  &__logo{
    svg{
      width: 200px;
      max-width: 100%;
    }
  }

}