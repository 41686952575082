.copyable-value-text {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  span {
    word-break: break-all;
  }

  svg {
    flex: 0 0 20px;
    margin-left: 12px;
  }
}
