.auth-stepper {
  display: flex;
  align-items: center;
  margin: 0 -7px;
  list-style: none;
  background: rgba(0, 0, 0, 0.17);
  padding: 13px 17px;
  border-radius: 33px;

  li {
    width: 8px;
    margin: 0 7px;
    min-width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(#ffffff, .4);

    &.active {
      background-color: #B2E6D6;
      width: 12px;
      height: 12px;
      min-width: 12px;
    }

    &.past{
      background: var(--color-primary-green);
    }
  }

  //@media (max-width: 767px) {
  //  li {
  //    background: #6D5ECB;
  //    opacity: 0.4;
  //    width: 8px;
  //    height: 8px;
  //    min-width: 8px;
  //
  //    &.active {
  //      opacity: 1;
  //      background: #6D5ECB;
  //    }
  //  }
  //}
}