.hotel-room-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  &__text {
    display: block;
    margin-top: 40px;
    font-size: 15px;
    font-weight: 600;
  }

  &__subtext {
    display: block;
    margin-top: 10px;
    font-size: 15px;
  }
}
