

.contact-info-list{
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 26px;
}

.contact-info-list-item{
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  align-items: center;
  justify-content: flex-start;

  &__label{
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #8C8896;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  &__value{
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #24253D;
  }

}