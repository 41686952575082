.favorite-content-loader{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 30px;

  @media (max-width: 1070px) {
    & {
      grid-template-columns: 1fr 1fr
    }
  }
  @media (max-width: 850px) {
    & {
      grid-template-columns: 1fr;

    }
  }
}
.favorite-content-loader-item{
  margin-top: 40px;
}
.favorite-content-loader-item__body{
  border-radius: var(--app-card-radius);
  overflow: hidden;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 5px;
  margin-bottom: 10px;
  padding-bottom: 47%;
  height: 0;

  div{
    display: grid;
    gap: 5px;
  }
}
