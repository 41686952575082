.button-search {
  border: 1px solid #979797;
  border-radius: var(--app-card-radius);
  cursor: text;
  background: rgba(255, 255, 255, 0.2);
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  grid-gap: 30px;
  justify-items: flex-start;
  padding: 13px 22px;
  overflow: hidden;
  align-items: center;

  transition: .3s all;


  .button-search__text {
    text-align: left;
    width: 100%;
    color: #2F2C37;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    white-space: nowrap;
    min-width: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  svg {
    min-width: 18px;
    margin-bottom: -3px;
  }

  &--gray {
    .button-search__text {
      color: #8C8896;
    }

    background: #F4F2F6;
    border-color: #F4F2F6;

    height: 49px;

    svg {
      fill: #8C8896;
    }
  }

  &--light {
    background-color: var(--app-color-white);
    height: 64px;

    .button-search__text {
      color: #8C8896;
    }
  }

  &--fillable {

    border: 1px solid var(--app-color-transparent);

    .button-search__text {
      color: var(--app-color-white);
    }

    path {
      fill: white;

    }
  }
}
