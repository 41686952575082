.field-material-input {
  position: relative;
  margin-bottom: 25px;
  --padding: 16px;

  input {
    width: 100%;
    height: 56px;
    padding: 0 40px 0 var(--padding);
    font-size: 15px;
    border: 1px solid #C5C6CF;
    border-radius: 6px;

    &:focus {
      border: 1px solid var(--color-text-black);
      box-shadow: 0 0 0 1px var(--color-text-black);
    }

    &:disabled {
      background: #fff;
    }

    &:focus, &.withValue {
      padding-top: 15px;

      + label {
        font-size: 11px;
        top: 15px;
      }
    }
  }

  label {
    font-size: 15px;
    position: absolute;
    line-height: 22px;
    left: var(--padding);
    font-weight: 500;
    top: 50%;
    transform: translate(0, -50%);
    color: var(--color-text-gray);
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    user-select: none;
  }

  &__valid-check {
    position: absolute;
    right: var(--padding);
    top: 50%;
    transform: translate(0, -50%);
  }

  &__error-message {
    color: var(--color-primary-red);
    font-size: 12px;
    bottom: -18px;
    display: flex;
    align-items: center;
    position: absolute;

    svg {
      margin-right: 5px;
    }
  }

  &.isError {
    input {
      border-color: var(--color-primary-red);
      background-color: #FEF8F6;

      &:focus {
        box-shadow: 0 0 0 1px var(--color-primary-red);
      }
    }

    label {
      font-weight: 600;
      color: var(--color-primary-red);
    }
  }

  &.isLoading {
    opacity: 0.5;
  }

}
