.account-messages {
   //max-height: calc(100vh - 106px);
   height: calc(100vh - 154px - var(--header-height) - 90px);

   .account-messages__header {
      display: grid;
      grid-template-columns: 3fr 5fr;
      border-bottom: 1px solid #f0efef;
   }

   .account-messages__body {
      display: grid;
      grid-template-columns: 3fr 5fr;
      height: 100%;
   }
}

.account-messages-body__header {
}

.account-messages-header {
   .account-messages-header__info {
      padding: 18px;

      h4 {
         font-weight: 600;
         font-size: 21px;
         line-height: 31px;
         color: #2f2c37;
         //margin-bottom: 4px;
      }

      span {
         font-size: 13px;
         line-height: 19px;
      }
   }
}

.account-messages-body {
   .account-messages-body__users {
      border-right: 1px solid #f0efef;
      padding: 20px 24px;
      overflow: auto;
   }
   .account-messages-body__wrapper {
      //overflow: auto;
      height: 100%;
      display: grid;
      grid-template-rows: 1fr auto;
      overflow: hidden;

      .account-messages-body__outer {
         overflow: auto;
      }

      .account-messages-body__messages {
         padding: 20px 123px;
      }

      .account-messages-body__editable {
         border-top: 1px solid #f0efef;
         padding: 20px 123px;
         //padding: 14px;
      }
   }
}

.messages-mobile-header {
   position: sticky;
   z-index: 99;
   top: 0;
   display: grid;
   grid-template-columns: 38px 1fr;
   grid-column-gap: 20px;
   align-items: center;
   border-top: 1px solid #f4f2f6;
   border-bottom: 1px solid #f4f2f6;
   padding: 6px 20px;
   background-color: var(--app-color-white);

   .messages-mobile-header__image {
      width: 38px;
      height: 38px;
      border-radius: var(--app-card-radius);
   }

   .messages-mobile-header__text {
   }
   .messages-mobile-header__hotel-name {
      font-size: 13px;
      line-height: 19px;
      color: #2f2c37;

      span {
         margin-left: 5px;
         font-weight: 400;
         font-size: 13px;
         line-height: 19px;
         color: #8c8896;
      }
   }

   .messages-mobile-header__description {
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      color: #2f2c37;
   }
}

.messages-mobile {
   .modal-layout-primary__body {
      padding: 0;
      overflow: hidden;
   }

   .messages-mobile__outer {
      overflow: auto;
      height: 100%;
      padding: 0 20px;
   }
}

.conversations-mobile {
   .modal-layout-primary__body {
      padding-top: 0;
      padding-bottom: 0;
   }

   .conversations-navigation {
      position: sticky;
      top: 0;
      z-index: 199;
      background-color: white;
      padding: 0;
      border-right: none;
      padding-bottom: 20px;
      button{
         font-size: 11px;
      }
   }
}
