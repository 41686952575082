

.price-range {
  width: 100%;

  &__current-price-text {
    margin-top: 5px;
    //margin-bottom: 10px;
    font-size: 15px;
    line-height: 22px;
    color: var(--color-primary-red);
  }

  &__price-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    font-size: 15px;
    line-height: 22px;
    margin-top: 10px;
  }
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: var(--color-primary-red);
  box-shadow: none;
}

.rc-slider {
  &-mark-text {
    background-color: #E3E4EC;
    width: 8px;
    display: block;
    position: absolute;
    bottom: 20px;
    max-height: 100px;

    &-active {
      color: white;
      background-color: var(--color-primary-red);
    }
  }

  &-handle, &-handle-dragging {
    border: 5px solid var(--color-primary-red);
    box-shadow: 0 0 0 1px #fff !important;
    margin-top: -7px;
    width: 17px;
    height: 17px;
    background-color: #fff;

    &:active, &:hover, &:focus {
      border-color: var(--color-primary-red);
    }
  }

  &-dot {
    border: none;
    background-color: #E3E4EC;
    width: 1px;
    height: 1px;
  }

  &-track {
    height: 2px;
    background-color: #E47B79;
  }

  &-rail {
    height: 2px;
  }
}
