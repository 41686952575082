.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}


@mixin before-after-default-style {
  &:before, &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: var(--color-primary-50);
    z-index: -1;
  }

  &:after {
    background: var(--app-color-primary) !important;
    z-index: -1;
    border-radius: 50%;
  }
}

.field-datepicker {
  .field-datepicker__input {
    position: relative;
    display: block;
    width: 100%;
    appearance: none;
    height: var(--app-form-height-middle);
    border: 1px solid #C5C6CF;
    color: black;
    padding-left: 10px;
    padding-right: 35px;
    font-size: 16px;
    font-weight: 600;
    text-overflow: ellipsis;

    &:disabled {
      opacity: 0.8;
    }
  }

  .react-datepicker__navigation-icon {
    &::before {
      display: none;
    }
  }

  .react-datepicker {
    border-radius: var(--app-card-radius);
    padding: 0 20px 20px 20px;
    border: none;
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);

    //.react-datepicker__month-container {
    //  margin-right: 10px;
    //}
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    top: 34px;
  }

  .react-datepicker__navigation--next {
    right: 36px;
    //background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'> <rect width='24' height='24' fill='white' fill-opacity='0.01'/> <rect width='24' height='24' fill='white' fill-opacity='0.01'/><path fill-rule='evenodd' clip-rule='evenodd' d='M14.9949 10.995C15.1824 11.1825 15.2877 11.4368 15.2877 11.702C15.2877 11.9672 15.1824 12.2215 14.9949 12.409L10.4019 17.002C10.2156 17.1846 9.96482 17.2862 9.70399 17.2849C9.44317 17.2836 9.19339 17.1794 9.00896 16.995C8.82452 16.8105 8.72032 16.5608 8.71901 16.2999C8.71769 16.0391 8.81936 15.7883 9.00192 15.602L12.9019 11.702L9.00192 7.80201C8.81936 7.61572 8.71769 7.36491 8.71901 7.10408C8.72032 6.84325 8.82452 6.59348 9.00896 6.40904C9.19339 6.22461 9.44317 6.12041 9.70399 6.11909C9.96482 6.11777 10.2156 6.21944 10.4019 6.40201L14.9949 10.995Z' fill='#D1CFD5'/></svg>");
    border: none;
    width: 8px;
    height: 8px;
    border-top: 2px solid black;
    border-left: 2px solid black;
    transform: rotate(134deg);
  }

  .react-datepicker__navigation--previous {
    left: 36px;
    //background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" fill="white" fill-opacity="0.01"/><path d="M14 7L9 12" stroke="#D1CFD5" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/><path d="M9 12L14 17" stroke="#D1CFD5" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/></svg> ');
    border: none;
    width: 8px;
    height: 8px;
    border-top: 2px solid black;
    border-left: 2px solid black;
    transform: rotate(-45deg);
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: 0;
    padding-top: 0;
  }

  .react-datepicker__current-month {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-transform: capitalize;
    color: #2F2C37;
    padding: 24px 0 15px 0;
  }

  .react-datepicker__day-names {
    .react-datepicker__day-name {
      margin: 0;
      width: 42px;
      height: 42px;
      line-height: 42px;
      font-weight: 600;
      font-size: 13px;
      color: #3D3A45;
    }
  }

  .react-datepicker__month {
    .react-datepicker__week {
      margin-bottom: 2px;

      .react-datepicker__day--disabled {
        text-decoration: line-through;
      }

      .react-datepicker__day {
        margin: 0;
        border-radius: 0;
        font-size: 14px;
        font-weight: 400;
        width: 42px;
        line-height: 42px;

        &.react-datepicker__day--keyboard-selected {
          background-color: white;
          color: black;
        }

        &.react-datepicker__day--selected {
          &:not(.react-datepicker__day--outside-month) {
            background: var(--app-color-primary);
            color: white;
            border-radius: 50%;
          }
        }

        &:hover {
          &:not(.react-datepicker__day--in-selecting-range,.react-datepicker__day--in-range) {
            border-radius: 50%;
          }
        }

        &.react-datepicker__day--today {
          &:not(.react-datepicker__day--outside-month) {
            border: 1px solid var(--app-color-primary);
            background-color: white;
            border-radius: 50%;
            color: #000;
          }
        }

        &:not(.react-datepicker__day--outside-month) {
          &.react-datepicker__day--selecting-range-end,
          &.react-datepicker__day--range-end {
            background: var(--app-color-primary);
            color: white;
            border-radius: 50%;
            position: relative;
            border: none;
            z-index: 999;

            @include before-after-default-style();

            &:before {
              border-top-right-radius: 50%;
              border-bottom-right-radius: 50%;
            }
          }

          &.react-datepicker__day--keyboard-selected {
            @media (max-width: 767px) {
              //background: #6D5ECB;
              background-color: var(--app-color-primary);
              color: white;
              border-radius: 50%;
              position: relative;
              border: none;
              z-index: 999;
            }
          }

          &.react-datepicker__day--selecting-range-start,
          &.react-datepicker__day--range-start {
            //background: #6D5ECB;
            background: var(--app-color-primary);
            color: white;
            border-radius: 50%;
            position: relative;
            border: none;
            z-index: 999;

            @include before-after-default-style();

            &:before {
              border-top-left-radius: 50%;
              border-bottom-left-radius: 50%;
            }
          }

          &.react-datepicker__day--in-selecting-range:hover {
            background: var(--app-color-primary);
            color: white;
            border-radius: 50%;
            position: relative;
            border: none;
            z-index: 999;

            @include before-after-default-style();

            &:before {
              border-top-right-radius: 50%;
              border-bottom-right-radius: 50%;
            }
          }
        }

        &.react-datepicker__day--outside-month {
          background-color: transparent !important;
        }

        &.react-datepicker__day--in-selecting-range,
        &.react-datepicker__day--in-range {
          background: var(--color-primary-50);
          color: var(--app-color-primary)
        }
      }

      //}
    }
  }
}

.mobile-modal-calendar {

  //display: flex;
  //align-items: flex-end;

  //>div {
  //  height: 500px;
  //  overflow: auto;
  //  background: white;
  //  border-top-left-radius: 30px;
  //  border-top-right-radius: 30px;
  //}


}

