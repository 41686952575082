$size-xs: 480.98px;
$size-sm: 575.98px;
$size-md: 767.98px;
$size-lg: 991.98px;
$size-xl: 1200.98px;

@mixin breakpoints-xs {
  @media (max-width: $size-xs) {
    @content;
  }
}

@mixin breakpoints-sm {
  @media (max-width: $size-sm) {
    @content;
  }
}

@mixin breakpoints-md {
  @media (max-width: $size-md) {
    @content;
  }
}

@mixin breakpoints-lg {
  @media (max-width: $size-lg) {
    @content;
  }
}

@mixin breakpoints-xl {
  @media (max-width: $size-xl) {
    @content;
  }
}

@mixin breakpoints($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin mobileOnly() {
  @media (max-width: $size-md) {//or ((min-width: 320px) and (max-width: 900px) and (orientation: landscape)) {
    @content;
  }
}

@mixin tabletAndUpper() {
  @media (min-width: $size-md) {
    @content;
  }
}

// mixin for text

// mixin for flex

// mixin for grid

// mixin for display

// mixin for position
