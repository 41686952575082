@import "src/common/assets/styles/mixin";

.hotel-detail-order-summary-search-info {
  border-bottom: 1px solid rgba(#D1CFD5, 0.4);
  padding-bottom: 15px;
  margin-bottom: 15px;

  &.from-phones-section {
    border-bottom: none;
    padding: 0;
    margin: 0;
  }

  &__title {
    display: flex;
    margin-bottom: 8px;
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    justify-content: space-between;
    position: relative;
  }

  &__edit-button {
    padding: 5px 10px;
    position: absolute;
    top: 0;
    right: -5px;
    border: none;
    background-color: transparent;

    @include mobileOnly() {
      top: -5px;
      right: -10px;

      svg {
        width: 18px;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  &__info-row {
    font-size: 13px;
    display: flex;
    align-items: center;
    color: var(--color-text-gray);
    margin: 2px 0;

    svg {
      margin-right: 5px;
      margin-top: -1px;
    }

    @include mobileOnly() {
      font-size: 15px;
    }
  }
}
