.book-button {
  width: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  .price-viewer {
    font-size: 18px;
    font-weight: 600;
    margin-left: 5px;
  }

  &:hover {
    border-color: var(--color-primary-red);
    background-color: var(--color-primary-red);
  }
}
