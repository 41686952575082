.no-trips{
  border: 1px solid #C5C6CF;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  padding: 22px 100px 12px 42px;
  margin-bottom: 40px;
  @media (max-width: 850px){
    flex-direction: column;
    align-items: center;
    padding: 0;
    border: none;
  }
  &__block-information{

  }
  &__title{
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: #2F2C37;
    margin-top: 111px;
    margin-bottom: 7px;
    @media (max-width: 850px){
      margin-top: 31px;
    }
  }
  &__info{
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin-top: 26px;
    margin-bottom: 42px;
    @media (max-width: 850px){
      display: none;
    }

  }
  &__button {
    @media (max-width: 850px){
      display: none;
    }
    &.fillable {
      color: white;
    }
  }
  &__button-mobile{
    margin-top: 97px;
    width: 100%;
    @media (min-width: 850px){
      display: none;
    }
    &.fillable {
      color: white;
    }
  }
}