// component
.orders-text-with-description{
  //padding-bottom: 24px;

  .orders-text-with-description__text{
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #2F2C37;
    margin-bottom: 6px;
  }

  .orders-text-with-description__description{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #3D3A45;
  }
}