.hotel-room-item--phone-book {
  &__content {
    width: 100%;
  }

  &__content-sections {
    display: flex;
    margin-top: 30px;
    font-size: 15px;
    color: #717171;
    justify-content: space-between;

    > div {
      flex: 1;
    }

    button {
      background: none;
      cursor: pointer;
      text-align: left;
      line-height: 21px;
      overflow: hidden;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }


    }
  }

  &__content-sections-title {
    color: #000;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .room-item-price__nights-persons-icons {
    margin-top: 10px;
  }

  &__top-row {
    display: flex;
    justify-content: space-between;
  }
}
