// modal for mobile datepicker
.modal-mobile {
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 100%;
  display: flex;
  align-items: flex-end;

  .modal-mobile__header {
    position: sticky;
    top: 0;
    z-index: 99999;
    background-color: white;
  }

  &__background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  &__body {
    padding: 0 24px 24px;
    position: relative;
    z-index: 10;
  }

  .modal-mobile__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F4F2F6;
    position: fixed;
    bottom: 0;
    z-index: 99998;
    background-color: white;
  }

  .modal-mobile__inner {
    position: relative;
    max-height: 90%;
    width: 100%;
    overflow: auto;
    background-color: white;
    max-width: 100%;

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    line-height: 0.8;
    z-index: 400;
  }

  //.modal-mobile__close{
  //  position: absolute;
  //  cursor: pointer;
  //  bottom: calc(85% + 13px);
  //  left: 24px;
  //  z-index: 1001;
  //}
}
