
.links-block {

  h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #2F2C37;
    margin-bottom: 17px;

    @media (max-width: 767px){
      font-weight: 400;
      font-size: 21px;
      color: #2F2C37;
    }
  }

  .links-block__list {
    list-style: none;
    margin-bottom: 32px;
  }

  .links-block__item {

  }

}
