.contacts-social {

  &__title {
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: #181B20;
    margin-bottom: 24px;
  }

  &__list {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    //grid-template-columns: repeat(4, auto);
    justify-content: flex-start;
    list-style: none;
  }

  &__item {
    cursor: pointer;
  }
}