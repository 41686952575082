
.profile-password__send-code{

}

.profile-send-code{
  grid-gap: 20px;
  align-items: center;
  max-width: 435px;


  .profile-send-code__form{

  }


  .profile-send-code__send-code{
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #8C8896;
    //max-width: 350px;
    margin-top: -15px;
    margin-bottom: 50px;

    span:last-child {
      cursor: pointer;
      color: var(--app-color-primary);
    }

  }
}
