.reset-password {
   display: flex;
   flex-direction: column;

   .reset-password__phone-form {
         height: 100%;
      form {
         > div {
            display: grid;
         }
      }
   }

   form {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
   }
   //
   //.reset-password__description-mobile {
   //   display: none;
   //   @media (max-width: 767px) {
   //      text-align: center;
   //      margin-top: 63px;
   //      display: block;
   //   }
   //}

   button {
      align-self: flex-end;
      margin-top: 50px;
      width: 100%;
   }
}
