.mobile-modal-header {
  display: grid;
  grid-template-columns: 35px auto 35px;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px var(--app-blocks-padding);

  .mobile-modal-header__icon {
    cursor: pointer;
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .mobile-modal-header__title {
    font-weight: 600;
    display: block;
    font-size: 15px;
    line-height: 21px;
    color: var(--color-text-black);
    text-align: center;
  }

  .mobile-modal-header__description {
    display: block;
    margin-top: 5px;
    min-height: 22px;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: var(--color-text-gray);
  }
}

