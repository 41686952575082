.mobile-room-detail {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 16px 0;

  &__properties-title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;
  }

  .custom-carousel-out {
    height: 200px;
    flex: 0 0 200px;
    border-radius: 4px;
    margin-bottom: 18px;
    overflow: hidden;

    .custom-carousel {
      height: 200px;
    }

    .gallery-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }

  &__info-row-wrap {
    padding-bottom: 20px;
  }

  &__properties-list-wrapper {
    margin-bottom: 20px;
  }

  &__properties-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding-bottom: 10px;

    .amenities {
      overflow: hidden;
    }

    .amenities-text {
      font-size: 15px;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__book-section-texts {
    margin-top: 5px;
  }
}
