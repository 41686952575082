.carousel-secondary-arrow {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(0, -50%);
  background-color: rgba(255, 255, 255, 0.33);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
  transition: 0.2s all linear;
  z-index: 10;

  svg {
    fill: #000;
  }

  &:hover {
    background: rgba(255, 255, 255, 1);
    cursor: pointer;

    svg {
      fill: var(--color-primary-green);
    }

    &.disabled {
      opacity: 0.5 !important;
    }
  }

  &--prev {
    left: 10px;
  }

  &--next {
    right: 10px;
  }

  &.disabled {
    opacity: 0.5;
  }
}
