
.messages-list {
  //overflow: auto;
  //max-height: 100%;

  .messages-list__date {
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #8c8896;
    text-align: center;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 31px;
    }
  }
}