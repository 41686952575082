@import "src/common/assets/styles/mixin";

.reservation-edit-modal-room-types-list {
  &__select-button {
    display: flex;
    text-align: left;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--color-light-gray);
    padding-bottom: 16px;
    justify-content: space-between;
    cursor: pointer;
    background: transparent;

    > svg {
      opacity: 0.5;
    }
  }

  &__select-list {
    position: absolute;
    background: #fff;
    backdrop-filter: blur(4px);
    padding-top: 8px;
    overflow: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;

    @include mobileOnly() {
      position: static;
      padding-top: 0;
      backdrop-filter: none;
    }
  }

  &__select-list-button {
    width: 100%;
    display: block;
    cursor: pointer;
    text-align: left;
    background-color: transparent;

    &:hover {
      background-color: var(--common-borders-color);
      backdrop-filter: blur(4px);
    }
  }

  &__content {
    padding: 16px 0;
  }

  &__item {
    width: 100%;
    padding: 8px 24px;

    @include mobileOnly() {
      padding-left: 0;
      padding-right: 0;
    }

    &.selected {
      padding: 0;
    }
  }

  &__item-content {
    display: grid;
    grid-template-columns: 64px 1fr;
    grid-gap: 16px;
  }


  &__item-image-wrap {
    width: 100%;
    height: 64px;
    background-color: var(--color-image-background);
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item-texts {
    font-size: 13px;
    line-height: 19px;
    color: var(--color-text-gray);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__item-texts-category {
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: var(--color-text-black);
  }

  &__item-texts-name {
    display: flex;
    max-height: 25px;
    width: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

}
