@import "src/common/assets/styles/mixin";

.hotels-list-actions {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 8px;

  button {
    width: 100%;
  }

  &--is-on-map {
    @include mobileOnly() {
      grid-template-columns: auto auto;
    }

    .hotels-list-actions__sort-icon {
      @include mobileOnly() {
        display: none;
      }
    }
  }



  .button-secondary__icon {
    display: flex;
    align-items: center;
  }

  ////Todo: move to css vars
  @media all and (max-width: 900px) and (min-width: 767.98px) {
    margin-left: 10px;

    .button-secondary {
      width: 40px;
      justify-content: center;

      .button-secondary__block {
        margin: 0;
      }

      .button-secondary__text {
        display: none;
      }
    }
  }

  @include mobileOnly() {
    width: 100%;
    //grid-gap: 10px;
    padding: 16px var(--app-blocks-padding);

    @media (max-width: 350px) {
      padding: 16px 5px;
    }

    .button-secondary {
      height: 32px;
      justify-content: center;

      .button-secondary__text {
        font-weight: normal;
      }

      .button-secondary__block {
        margin: 0 8px;
      }
    }
  }

  @media all and (max-width: 380px) {
    grid-gap: 5px;

    .button-secondary__text {
      margin-left: 5px;
    }
  }

  @media all and (max-width: 350px) {
    .button-secondary {
      svg {
        display: none;
      }

      .button-secondary__block {
        margin: 0;
        width: 100%;
        justify-content: center;
      }

      .button-secondary__text {
        margin: 0;
        text-align: center;
        font-weight: normal;
      }
    }
  }
}
