@import "src/common/assets/styles/mixin";

.modal-layout-primary {
  --header-height: 72px;
  //display: grid;
  //grid-template-rows: var(--header-height) auto;
  overflow: auto;
  height: 100%;
  min-width: 400px;

  display: flex;
  flex-direction: column;

  @media all and (max-width: 500px) {
    min-width: 100%;
  }

  &--with-footer {
    grid-template-rows: var(--header-height) auto auto;
  }

  &__title {
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: var(--color-text-black);
    text-align: center;
    min-height: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ebebeb !important;
    padding: 10px 60px;
  }

  &__sub-title {
    &.app-text {
      font-size: 15px;
      text-align: center;
    }
  }

  @include mobileOnly() {
    .modal-layout-primary__title-right-content {
      //position: absolute;
      //top: 13px;
      //right: 0;
      //z-index: 1000;
    }
  }

  &__header {
    position: relative;

    .modal-layout-primary__title {
      border-bottom: none !important;
    }
  }

  &__body {
    overflow: auto;
    padding: var(--app-blocks-padding);
    overscroll-behavior: contain;
    flex: 1;
  }

  .modal-layout-primary__footer {
    width: 100%;
    border-top: 1px solid #ebebeb;
    padding: 16px 20px calc(env(safe-area-inset-bottom) + 16px);

    align-self: flex-end;
  }

  &--no-footer-padding {
    .modal-layout-primary__footer {
      padding: 0;
    }
  }

  &:not(.modal-layout-primary--disable-full-size-styles-on-mobile) {
    .modal-layout-primary__footer {
      @include mobileOnly() {
        border-top: none;
        position: sticky;
        bottom: 0;
        background: white;
        overflow: hidden;
        border-top: 1px solid #F4F2F6;
      }
    }

    .modal-layout-primary__title {
      @include mobileOnly() {
        border-bottom: none;
        padding: 10px 55px;
        font-weight: normal;
        font-size: 15px;
        position: sticky;
        line-height: 25px;
        top: 0;
        z-index: 10;
        background: #fff;
      }
    }

  }

  &--no-padding {
    .modal-layout-primary__body {
      padding: 0;
    }
  }

  &--no-padding-top {
    .modal-layout-primary__body {
      padding-top: 0;
    }
  }

  &--footer-variant-2 {
    .modal-layout-primary__footer {
      border-radius: 12px 12px 0 0;
      background: #F4F2F6 !important;
      border-top: 1px solid #F4F2F6;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.65);
    }
  }

  &--footer-variant-3 {
    .modal-layout-primary__footer {
      border-radius: 8px 8px 0 0;
      background: white !important;
      border-top: none !important;
      //border-top: 1px solid #F4F2F6;
      //box-shadow: 0 4px 24px rgba(0, 0, 0, 0.65);
    }
  }

  &__header-scroll-content {
    border-bottom: 1px solid #ebebeb !important;
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    top: 100%;
    transform: translate(0, -1px);
    padding: 0 var(--app-blocks-padding);
    z-index: 9;
  }

  &__header-scroll-content-hidden {
    transform: translate(0, calc(-100% + 1px));
    z-index: 1;
  }

  &__header-scroll-content--with-transition {
    transition: 0.3s all linear;
  }
}

.modal {
  display: flex;
  flex-direction: column;

  .modal__content {
    overflow: hidden;
  }
}
