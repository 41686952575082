@import "../../../../../assets/styles/mixin";

.hotel-rules {
  padding-bottom: 50px;

  &__list {
    display: grid;
    grid-template-columns: 250px 1fr;
    //grid-template-rows: 1fr 1fr ;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: var(--color-text-black);
    width: 70%;
  }

  &__list-item {
    margin-bottom: 16px;
    //width: 250px;
    //width: 80%;
  }

  @include mobileOnly() {
    &__list {
      width: 100%;
      display: block;
    }

    &__list-item {
      width: 100%;
    }
  }

}
