

.clickable-arrow-item{
  padding: 20px 10px 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .clickable-arrow-item__arrow{
    transform-origin: center;
    transition: .3s all;

    &.top{

    }
    &.left{

    }

    &.bottom{
      transform: rotate(90deg);
    }

    &.right{

    }
  }


}
