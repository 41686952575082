.field-material-input-edit-button {
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  padding: 16px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 6px;

  &__save-label {
    font-size: 13px;
  }

  &:hover {
    svg {
      path {
        stroke: var(--color-primary-green);
      }

    }

    .field-material-input-edit-button__save-label {
      color: var(--color-primary-green);
    }

  }
}
