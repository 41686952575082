@import "../../../../../assets/styles/mixin";

.hotel-amenities {
  //max-width: 720px;

  &__list-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    i {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    span {
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 50px;
    align-items: flex-start;

    @include mobileOnly() {
      font-weight: normal;
      grid-template-columns: 1fr;
    }
  }

  &__list-item {
    margin-bottom: 16px;
    overflow: hidden;

    .show-more-button {
      margin-top: 0;
    }

    @include mobileOnly() {
      margin-bottom: 8px;
      //margin-right: 10px;
      overflow: hidden;
      //text-overflow: ellipsis;

      .amenities-text {
        //overflow: hidden;
        width: 100%;
        //white-space: nowrap;
        //text-overflow: ellipsis;
        color: var(--color-text-dark);
        font-weight: normal;
      }
    }
  }

  &__button {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: var(--color-primary-red);
    background-color: white;
    cursor: pointer;
    margin-bottom: 37px;
    margin-top: 14px;
  }
}
