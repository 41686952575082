.custom-link {
  //display: block;
  position: relative;
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  //border-bottom: 1px solid #F4F2F6;

  &:before{
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 0;
    background-color: #F4F2F6;
    width: calc(100% - 25px);
  }


  .custom-link__title {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #3D3A45;
  }

  .custom-link__value {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #8C8896;
    display: flex;
    align-items: center;

    svg{
      margin-left: 17px;
    }
  }
}