@import "src/common/assets/styles/mixin";

.mobile-modal-calendar {
  --header-height: 50px;

  .mobile-modal-header {
    align-items: flex-start;
    height: var(--header-height);
    grid-row-gap: 0;
  }

  .mobile-modal-header__icon {
    align-items: flex-start !important;
  }

  .mobile-modal-header__text {
    overflow: hidden;

    display: flex;
    justify-content: flex-start;
    //align-items: center;
    flex-direction: column;
  }

  .mobile-modal-header__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .modal-mobile__inner {

  }

  .mobile-modal-calendar__header {
    padding: 24px 24px 0 24px;

    .mobile-modal-calendar__notify{
      color: var(--app-color-primary);
      font-size: 14px;
      line-height: 19px;
      margin-top: 10px;
      margin-bottom: 10px;

    }
    .mobile-modal-header {
      padding: 0;
    }

    h4 {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      color: #2F2C37;
      margin: 20px 0;
    }

    .react-datepicker__day-names {
      border-bottom: 1px solid #F4F2F6;
      display: flex;

      .react-datepicker__day-name {
        font-size: 15px;
        color: #8C8896;
        flex: 1;
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
  }

  .mobile-modal-calendar__body {
    //padding: 0 25px;
  }


  .react-datepicker {
    box-shadow: none;
    padding: 20px 0;
    width: 100%;
    flex-direction: column;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .react-datepicker__month-container {
      width: 100%;
      margin: 0;
    }

    .react-datepicker__day {
      --absolute-width: 100vw;
      //padding-top: calc(50% / 7);
      //padding-bottom: calc(50% / 7);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: calc(var(--absolute-width) / 7 - calc(var(--app-blocks-padding) * 2 / 7)) !important;
      width: calc(var(--absolute-width) / 7 - calc(var(--app-blocks-padding) * 2 / 7)) !important;
      line-height: 0 !important;
      outline: none;

      @media (orientation: landscape) {
        --absolute-width: 100vh;
      }

      //transition: 0.2s background-color linear;

      //&.react-datepicker__day--range-start,
      //&.react-datepicker__day--range-end {
      //  transition: none;
      //}

      &.react-datepicker__day--outside-month {
        &:before {
          content: "0";
          visibility: hidden;
        }
      }
    }

    .react-datepicker__current-month {
      padding: 18px 0 10px 8px;
    }

    .react-datepicker__header {
      position: sticky;
      top: calc(var(--header-height) + 80px);
      z-index: 1000;


      .react-datepicker__day-names {
        display: none;
      }
    }
  }

  .react-datepicker__current-month {
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 25px;
    text-align: left;
    text-transform: capitalize;
  }

  .react-datepicker__navigation {
    display: none;
  }
}

