.search-modal-main {

  //
  .kids-age-select {
    ul {
      grid-template-columns: 1fr;
    }
  }


  .search-modal-main__header {
  }

  .search-modal-main__wrapper {
    //padding: 0 24px 24px;
    padding-bottom: 80px;
  }

  h3 {
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: #2F2C37;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .hotel-people-dropdown {
    position: static;
    width: 100%;
    box-shadow: none;
    padding: 0;
  }

  .modal-mobile__footer {
    border-top: none;
  }

  //.search-modal-main__footer{
  //  width: 100%;
  //  padding: 24px;
  //
  //}

  .search-modal-main__button {
    width: 100%;
  }
}
