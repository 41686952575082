.toolbar-mobile {
  position: fixed;
  z-index: var(--app-index-toolbar-mobile);
  //bottom: 0;
  left: 0;
  height: var(--mobile-footer-height);
  width: 100%;
  background-color: white;
  border-top: 1px solid rgba(228, 228, 228, 0.6);
  //bottom: env(safe-area-inset-bottom);
  bottom: 0;
  padding-bottom: calc(env(safe-area-inset-bottom));
  transition: 0.3s all linear;

  &.scrolled-down {
    transform: translate(0, 100%);
  }

  &.scrolled-up {}

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 260px;
    margin: auto;
    height: 100%;
  }

  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;


    li,a{
      line-height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      min-width: 40px;
    }

    li {

      &.active {
        position: relative;

        .toolbar-svg-stroke {
          stroke: var(--color-primary-red);
        }

        .toolbar-svg-fill {
          fill: var(--color-primary-red);
        }
      }
    }
  }
}
