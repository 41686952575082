//@media print {
//   @page {
//      size: 210mm 297mm;
//      margin: 5mm 0;
//   }
//
//   .header,
//   .page__header,
//   .toolbar-mobile,
//   .account-orders-print-button,
//   .breadcrumbs-mobile {
//      display: none;
//   }
//
//   .account-orders-print__wrapper {
//      gap: 50px !important;
//   }
//}

.account-orders-print-button {
   @media only screen and (max-width: 767px) {
      button {
         width: 100%;
      }
   }
}

//.account-orders-print {
//   @media only screen and (max-width: 767px) {
//      margin-top: 20px;
//   }
//
//   .account-orders-print__title {
//      margin-bottom: 14px;
//      font-weight: 600;
//      font-size: 27px;
//      line-height: 40px;
//      color: #2f2c37;
//   }
//   .account-orders-print__header {
//      display: flex;
//      justify-content: space-between;
//      align-items: center;
//
//      @media only screen and (max-width: 580px) {
//         display: block;
//      }
//   }
//
//   .account-orders-print__description {
//      font-weight: 400;
//      font-size: 15px;
//      line-height: 22px;
//      color: #3d3a45;
//      margin-bottom: 24px;
//      max-width: 380px;
//
//      b {
//         font-weight: 500;
//      }
//   }
//
//   .account-orders-print__status {
//      @media only screen and (max-width: 580px) {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         margin-bottom: 24px;
//      }
//
//      div {
//         display: block;
//         font-weight: 600;
//         font-size: 15px;
//         line-height: 22px;
//         color: #3d3a45;
//         text-align: right;
//
//         &:last-child {
//            font-weight: 400;
//            font-size: 15px;
//            line-height: 22px;
//            text-align: right;
//            color: #3d3a45;
//         }
//      }
//   }
//   .account-orders-print__wrapper {
//      margin-bottom: 60px;
//      display: grid;
//      grid-template-columns: 1fr 1fr;
//      gap: 132px;
//
//      @media only screen and (max-width: 980px) {
//         gap: 40px;
//      }
//      @media only screen and (max-width: 767px) {
//         grid-template-columns: 1fr;
//      }
//   }
//}
//
//.account-orders-detail-footer {
//   border-top: 1px solid #d1cfd5;
//   padding-top: 30px;
//   margin-bottom: 118px;
//   font-weight: 400;
//   font-size: 15px;
//   line-height: 22px;
//   color: #3d3a45;
//
//   @media (max-width: 767px) {
//      margin-bottom: 30px;
//   }
//}

//.reservation-hotel-info {
//   h5 {
//      font-weight: 600;
//      font-size: 21px;
//      line-height: 31px;
//      color: #2f2c37;
//      margin-bottom: 12px;
//   }
//
//   div {
//      font-weight: 400;
//      font-size: 15px;
//      line-height: 22px;
//      color: #3d3a45;
//   }
//}
//
//.reservation-summary-price {
//   background: #f4f2f6;
//   padding: 16px;
//   margin: 0 -16px;
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 24px;
//
//   &__label {
//      font-weight: 600;
//      font-size: 17px;
//      line-height: 25px;
//      display: flex;
//      align-items: center;
//      color: #2f2c37;
//   }
//
//   &__data {
//      font-weight: 600;
//      font-size: 17px;
//      line-height: 25px;
//      color: #2f2c37;
//   }
//}
//
//.reservation-payment {
//   &__has-payment {
//      display: flex;
//      justify-content: space-between;
//      margin-bottom: 24px;
//   }
//
//   &__card {
//      font-weight: 400;
//      font-size: 15px;
//      line-height: 22px;
//      color: #2f2c37;
//   }
//
//   &__date {
//      font-weight: 300;
//      font-size: 11px;
//      line-height: 16px;
//      color: #2f2c37;
//   }
//
//   &__price {
//      font-weight: 400;
//      font-size: 15px;
//      line-height: 22px;
//      color: #2f2c37;
//   }
//}
//
//.reservation-payment-summary {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//
//   &__label {
//      font-weight: 600;
//      font-size: 17px;
//      line-height: 25px;
//      color: #2f2c37;
//   }
//   &__data {
//      font-weight: 600;
//      font-size: 17px;
//      line-height: 25px;
//      color: #2f2c37;
//   }
//}
