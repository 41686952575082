.reservation-edit-modal-living-variants {
  &__list {
    //height: 165px;
    overflow-y: auto;
    scroll-snap-type: y proximity;
    margin-top: 16px;
    margin: 16px -10px 0 -10px;

    .field-radio.marker-position-right {
      border-radius: 8px;
      position: relative;
      padding-left: 10px;
      padding-right: 10px;

      &:hover {
        background-color: #F4F2F6;
      }
    }

    .field-radio__label:hover {
      opacity: 1;
    }
  }

  &__list-item-content {
    height: 55px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &__title {
      font-size: 13px;
      line-height: 19px;
      color: var(--color-text-black);
    }

    &__nights-text {
      display: block;
      font-size: 13px;
      line-height: 19px;
      font-weight: 500;
    }

    &__price {
      font-size: 17px;
      line-height: 25px;
      font-weight: 600;
    }

    > div:first-child {
      display: flex;
      align-items: center;
    }

    &__right-texts {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    svg {
      fill: #ccc;
      width: 32px;
      height: 32px;
      margin-right: 12px;

      &.RoomPriceMealIconFB {
        fill: none;
        stroke: #ccc;
      }
    }
  }

  &__no-results {
    font-size: 15px;
    line-height: 22px;
    padding: 3px 16px;
    background-color: #FFEFED;
    border-radius: 8px;
  }

  &.with-blur {
    filter: blur(2px);
  }
}
