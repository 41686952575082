.tabs-list {
  background: #F4F2F6;
  list-style: none;
  display: grid;
  grid-gap: 4px;
  border-radius: 8px;
  padding: 4px;
  grid-template-columns: auto auto;
  margin: 12px 0 16px;

  li {
    flex: 1;
  }

  button {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    transition: 0.2s all ease-in-out;

    &.active {
      background: #65CDAE;
      cursor: default;
      color: #fff;
    }

    &:hover {
      //backdrop-filter: contrast(0.95);
    }
  }
}
