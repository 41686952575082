@import "src/common/assets/styles/mixin";

.order-success {
  padding-bottom: 90px;
  padding-top: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: normal;
    color: #000000;
    margin-bottom: 50px;

    @include mobileOnly() {
      font-weight: 500;
      font-size: 27px;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  @include mobileOnly() {
    padding-top: 0;
  }

  &__text {
    width: 550px;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    line-height: 21px;
    color: #3D3A45;
    margin-top: 16px;
    margin-bottom: 24px;

    @include mobileOnly() {
      width: 100%;
      text-align: left;
    }

    a {
      color: #000;
      font-weight: 600;
    }
  }

  &__button {
    min-width: 320px;
    max-width: 100%;
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @keyframes moonAnimationIn {
    0% {
      margin: 0;
    }

    100% {
      margin: 65px 0 55px;
    }
  }

  .moon-image-container {
    margin: 0;
    animation: moonAnimationIn 1s forwards;

    @include mobileOnly() {
      animation: none;
      margin: 15px 0 10px;
    }
  }
}


