
.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;



  .modal-buttons__cancel {

    .button-preloader__dot{
        background-color: var(--app-color-disabled);
    }
    background: rgba(#fff, 0);
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #6D7188;
    padding: 10px;
    transition: .2s all;
    border: none;

    &:hover{
      color: var(--color-primary-red)
    }
  }
}