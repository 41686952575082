@import "../../../../../assets/styles/mixin";

.hotel-location-container {
  @include mobileOnly() {
    .hotel-detail-content-block__title {
      margin-bottom: 4px;
    }
  }
}

.hotel-location {
  padding-bottom: 64px;

  &__map-container {
    margin-top: var(--app-blocks-padding);
    border-radius: 8px;
    overflow: hidden;
    height: 480px;
    cursor: pointer;
    width: 100%;
  }

  &__map-outer {
    position: relative;
    z-index: 1;
  }

  &__map-modal-container {
    min-height: 480px;

    @include mobileOnly() {
      height: 100vh;
    }
  }

  &__map-container-inner {
    width: 100%;
    height: 100%;
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  @include mobileOnly() {
    padding-bottom: 0;

    &__map-container {
      height: 190px;
      margin-top: 10px;
    }
  }

  &__address {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: var(--color-text-gray);

    @include mobileOnly() {
      font-weight: 400;
      font-size: 13px;
    }
  }

  &__map-popup {

    .modal-close-button {
      left: auto;
      right: 24px;
    }

    .modal-layout-primary__title {
      display: none;
    }

    .modal-layout-primary {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;

      @include tabletAndUpper() {
        height: 480px;
        width: 1200px;
        max-width: 90vw;
      }
    }

    .modal-layout-primary__body {
      padding: 0;

      @include mobileOnly() {
        flex: 1;
      }
    }
  }
}
