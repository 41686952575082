@import '../../../../../assets/styles/main';

.field-radio-card {

  input {
    position: absolute;
    visibility: hidden;
  }

  &.disabled {
    position: relative;
    cursor: not-allowed;
    user-select: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}
