@use "sass:map";

//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@400;500;600;700&display=swa');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
@import "~toastr";
@import "notify";
@import "normalize";
@import "reset";
@import "mixin";
@import "variables";
@import "css-variales";
@import "utils";
@import "progressbar";

//@import 'node_modules/slick-carousel/slick/slick.css';
//@import 'node_modules/slick-carousel/slick/slick-theme.css';

a {
  color: #034cde;
  text-decoration: none;
}

$font-weights: ("Light": 300, "Regular": 400, "Semibold": 600, "Bold": 700);

@each $weightName, $weight in $font-weights {
  @font-face {
    font-family: 'Proxima Nova';
    src: local('Proxima Nova #{$weightName}'), local('Proxima-Nova-#{$weightName}'),
    url('../fonts/ProximaNova-#{$weightName}/ProximaNova-#{$weightName}.woff2') format('woff2'),
    url('../fonts/ProximaNova-#{$weightName}/ProximaNova-#{$weightName}.woff') format('woff'),
    url('../fonts/ProximaNova-#{$weightName}/ProximaNova-#{$weightName}.ttf') format('truetype');
    font-weight: $weight;
    font-style: normal;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova', sans-serif;
}

//@media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
//  html {
//    transform: rotate(-90deg);
//    transform-origin: left top;
//    width: 100vh;
//    min-height: 100vw;
//    overflow-x: hidden;
//    position: absolute;
//    top: 100%;
//    left: 0;
//  }
//}

body {
  @media (max-width: 767px) {
    background-color: #fffeff;
  }

  .header-mobile {
    &:not(.header-mobile--index-variant) {
      @media (max-width: 767px) {
        background-color: #fffeff;
      }
    }
  }
}

html, body, #root {
  height: 100%;
}

html {

}

body {
  overflow: auto;
}


.text {
  font-size: 16px;

  p {
    margin-bottom: 15px;
  }
}

//#root {
//  //height: 100%;
//  //overflow: hidden;
//}

.layout-container {
  //height: 100%;
  //height: 100%;
  //overflow: auto;

}

// hide captcha badge
.grecaptcha-badge{
  visibility: hidden;
}

.hide-on-mobile {
  @include mobileOnly() {
    display: none;
  }
}
