.price-container-prices {
  &__block-price {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    > div {
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__price {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    color: var(--app-color-primary);
  }

  &__discount {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #8C8896;
    text-decoration: line-through;
  }

  &__days {
    color: #8C8896;
    font-size: 13px;
    line-height: 19px;
  }

  &__meals {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    margin-top: 3px;
    color: var(--color-text-gray);
  }
}
