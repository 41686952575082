.account-orders-detail-hotel-item {
   display: grid;
   grid-template-columns: auto 1fr;
   grid-gap: 16px;
   margin-bottom: 15px;
   border-bottom: 1px solid rgba(#c5c6cf, 0.3);

   &:last-child {
      border-bottom: 0;
   }

   padding-bottom: 15px;

   @media (max-width: 767px) {
      margin-bottom: 25px;
      padding-bottom: 0;
      border-bottom: 0;
      grid-template-columns: 1fr;
   }

   &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
   }

   &__title {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #2f2c37;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin-bottom: 7px;

      @media (max-width: 767px) {
         font-weight: 500;
         font-size: 17px;
         line-height: 25px;
         color: #2f2c37;
         margin-bottom: 4px;
      }

      span {
         font-weight: 400;
         font-size: 17px;
         line-height: 25px;
         color: #8c8896;
      }
   }

   &__description {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #8c8896;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 767px) {
         margin-bottom: 5px;
      }

      span {
         font-weight: 600;
         font-size: 15px;
         line-height: 22px;
         color: #2f2c37;
      }
   }

   &__image {
      width: 106px;
      border-radius: var(--app-card-radius);

      @media (max-width: 767px) {
         width: 100%;
      }
   }

   &__name {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #2f2c37;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 767px) {
         margin-bottom: 10px;
      }

      button {
         font-weight: 500;
         font-size: 13px;
         line-height: 19px;
         color: var(--color-primary-red);
         cursor: pointer;
         border: none;
         outline: none;
         appearance: none;
         background-color: transparent;
      }
   }
}
