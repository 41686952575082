.account-orders-detail-prime {

   .custom-carousel-out{
      @media (max-width: 767px){
         margin: 0 -25px;
      }

      .carousel.carousel--secondary .slick-slider .slick-dots {
         bottom: 8px;
      }
   }

   .account-orders-detail-prime__info {
      padding-top: 34px;

      @media (max-width: 767px){
         padding-top: 24px;
      }

      p {
         font-weight: 400;
         font-size: 15px;
         line-height: 22px;
         color: #3d3a45;
         margin-bottom: 24px;
      }
      button {
         width: 100%;
      }
   }
}

.account-orders-detail-prime-header {
   display: flex;
   justify-content: space-between;
   margin-bottom: 28px;

   @media (max-width: 767px){
      display: none;
   }

   h1 {
      font-weight: 600;
      font-size: 27px;
      line-height: 40px;
      color: #2f2c37;
   }
}

.account-orders-detail-main-dates {
   margin-top: 28px;
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 20px;
   padding-bottom: 20px;
   margin-bottom: 20px;
   border-bottom: 1px solid #f4f2f6;
}

.account-orders-detail-main-guest {
   border-bottom: 1px solid #f4f2f6;
   //margin-bottom: 20px;
   padding-bottom: 20px;
}

.account-orders-detail-main-label {
   font-weight: 500;
   font-size: 17px;
   line-height: 25px;
   color: #2f2c37;
}
.account-orders-detail-main-data {
   font-weight: 400;
   font-size: 15px;
   line-height: 22px;
   color: #3d3a45;
}
