.room-item {
  padding-top: 18px;
  padding-bottom: 16px;
  border-bottom: 1px solid #C5C6CF;

  &__block-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  &__name {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #2F2C37;
  }

  &__change-button {
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #5ECBAA;
    background-color: white;
  }

  &__block-detail {
    margin-bottom: 12px;
  }

  &__room-item__detail-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #8C8896;
  }

  &__block-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #8C8896;
  }

  &__price {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #3D3A45;
  }
}