.mobile-rooms-list-modal, .mobile-rooms-list-modal-list {
  .modal-layout-primary__body {

  }

  .modal-layout-primary__title {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modal-layout-primary__sub-title {
    text-overflow: ellipsis;
    width: 108%;
    position: relative;
    //left: 4%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.mobile-rooms-list-modal-list {
  .modal-layout-primary, .modal-layout-primary__title {
    transition: 0.2s all ease-in-out;
    //background-color: #F8F8F8;
    background-color: #FFFFFF;
  }
}
