.order-list-loader{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  >div{
    border-radius: var(--app-card-radius);
    overflow: hidden;
  }

  @media (max-width: 900px){
    grid-template-columns: 1fr 1fr
  }

  @media (max-width: 580px){
    grid-template-columns: 1fr
  }
}