@import "src/common/assets/styles/mixin";

.order-index-summary-reservations {
  padding: 16px 0;

  @include mobileOnly() {
    padding: 0;
  }

  &__item {
    padding: 16px 0;
    border-bottom: 1px solid var(--color-light-gray-033);

    @include mobileOnly() {

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border: none;
      }
    }

  }

  &__name {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--color-text-black);
  }

  &__bottom-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
  }

  &__price-text {
    color: var(--color-text-gray);
    font-size: 15px;
  }

  &__row {
    display: flex;
    margin-top: 16px;
    min-height: 22px;
    align-items: center;
    justify-content: space-between;
  }

  &__payment-container {
    margin-top: 12px;
  }

  &__before-payment, &__total-row {
    font-weight: 600;
  }

  &__after-payment {
    margin-top: 4px;
    font-weight: 400;
    color: var(--color-text-gray);

    .price-viewer {
      color: var(--color-text-black);
    }
  }

  &__total-row {
    margin-top: 16px;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-light-gray-033);
  }
}
