.account-orders-detail-page {

  .page__content {
    position: relative;
    min-height: calc(95vh - var(--header-height));
  }
}

.account-orders-detail {
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  @media (max-width: 767px) {
    padding-bottom: 83px;
  }

  .account-orders-detail__left {
    max-width: 840px;
    background-color: white;
    position: relative;
    z-index: 2;
    height: 100%;
    overflow: auto;
    padding: 0 24px 24px 24px;

    @media (max-width: 767px) {
      min-width: 100%;
      max-width: 100%;
    }

  }

  .account-orders-detail__right {
    width: 100%;
    height: 100%;
  }
}


.account-orders-detail-map {
  width: 100%;
  height: calc(95vh - var(--header-height));
  position: relative;

  > div {
    height: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }
}
