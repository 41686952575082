
.mobile-modal-buttons{
  padding: 16px 24px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 10px;
  width: 100%;
  min-height: 79px;

  button {
    max-width: 156px;
    min-width: 0;
  }

  .mobile-modal-buttons__cancel{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #3D3A45;
    padding-left: 0;
    padding-right: 0;
    background-color: rgba(#000,0);

  }

}
