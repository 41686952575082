@import "../../../../../../assets/styles/mixin";


.no-reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;

  @include mobileOnly() {
    padding: 20px 0;
  }

  svg {
    width: 215px;
    margin-bottom: 20px;
  }

  span {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;

    @include mobileOnly() {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
