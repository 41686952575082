@import "src/common/assets/styles/mixin";

.hotel-detail-gallery {
  display: grid;
  border-radius: 8px;
  overflow: hidden;
  margin: 12px 0 26px;
  height: 510px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0;
  gap: 7px;

  .hotel-detail-gallery__photo {
    display: flex;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--color-text-gray);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
    }

    &:hover {
      filter: brightness(0.6);
      transition: 0.2s all linear;
    }

    &:nth-child(1) {
      grid-area: 1 / 1 / 3 / 3;
    }

    &:nth-child(2) {
      grid-area: 1 / 3 / 2 / 4;
    }

    &:nth-child(3) {
      grid-area: 1 / 4 / 2 / 5;
    }

    &:nth-child(4) {
      grid-area: 2 / 3 / 3 / 4;
    }

    &:nth-child(5) {
      grid-area: 2 / 4 / 3 / 5;
    }
  }

  &.hotel-detail-gallery--length-4 {
    .hotel-detail-gallery__photo {
      &:nth-child(2) {
        grid-area: 1 / 3 / 3 / 4;
      }

      &:nth-child(3) {
        grid-area: 1 / 4 / 2 / 5;
      }

      &:nth-child(4) {
        grid-area: 2 / 4 / 3 / 5;
      }
    }
  }

  &.hotel-detail-gallery--length-3 {
    display: flex;

    .hotel-detail-gallery__photo {
      flex: 1;

      &:nth-child(1) {
        flex: 2;
      }
    }
  }

  &.hotel-detail-gallery--length-2, &.hotel-detail-gallery--length-1 {
    display: flex;

    .hotel-detail-gallery__photo {
      flex: 1
    }
  }

  @include mobileOnly() {
    width: 100%;
    height: 245px;
    display: block;
    margin: 0 0 16px;
    background: var(--color-light-gray);

    .slick-dots {
      bottom: 10px !important;
    }
  }

  &__mobile-photo {
    display: flex;
    width: 100%;
    height: 245px;
    background-size: cover;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
