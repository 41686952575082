.contacts {
  &__header {
    margin-bottom: 54px;


    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
}

.contacts-body {
  margin-bottom: 48px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 100px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 28px;
  }

  &__form {

  }

  &__block {

  }
}