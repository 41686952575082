@import "../../../../../../assets/styles/mixin";

.hotel-comments-modal {
  border-radius: 12px;
  position: relative;
  width: 746px;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }

  &__rating-container {
    background-color: var(--color-primary-blue);
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
  }

  &__sorting-button {
    width: 40px;
    height: 40px;
    background-color: white;
    filter: drop-shadow(0px 0px 20px rgba(140, 136, 150, 0.2));
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: right;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    //@include mobileOnly() {
    //  //position: absolute;
    //  top: 17px;
    //  right: 24px;
    //  z-index: 100;
    //}
  }

  &__sorting-button-wrap {
    position: relative;
    display: flex;
    flex: 1;
  }

  &__sorting-list {
    position: absolute;
    flex: 1;
    right: 0;
    top: calc(100% + 10px);
    background-color: #fff;
    z-index: 100;
    justify-content: flex-end;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 6px 24px;
  }

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: var(--color-text-black);
    margin-left: 26px;
    white-space: nowrap;

    @include mobileOnly() {
      font-weight: 500;
      font-size: 21px;
      line-height: 31px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 12fr;
    row-gap: 30px;
  }
}
