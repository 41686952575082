@import "src/common/assets/styles/utils";

.image {
  position: relative;
  display: block;
  line-height: 0;
  @extend .no-image;

  source, img {
    object-fit: cover;
    width: 100%;
  }

  &.image--aspect-ratio {
    source, img {
      display: block;
      width: 100%;
      object-fit: cover;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &--with-background-color {
    background-color: var(--color-image-background);
  }
}
