.account-list-is-empty{
  max-width: 444px;
  text-align: center;
  margin: 0 auto;

  svg{
    margin-bottom: 30px;
  }

  //>h1{
  //  font-weight: 600;
  //  font-size: 32px;
  //  line-height: 48px;
  //  color: #000000;
  //  margin-bottom: 32px;
  //}

  >h5{
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: #2F2C37;
    margin-bottom: 16px;
  }

  >p{
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #8C8896;
  }
}