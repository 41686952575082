
.profile-preview {


  @media (max-width: 767px) {
    .profile-preview__list {
      .profile-navigation-item {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: 1px solid #F4F2F6;
        min-height: auto;
        box-shadow: none;
        border-radius: 0;
        padding: 18px;

        > div {
          display: flex;
          align-items: center;
        }


        .profile-navigation-item__title {
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: #2F2C37;
        }

        .profile-navigation-item__icon {
          margin-right: 18px;

          svg {
            height: 20px;

          }
        }

        > svg {
          path {
            fill: #6D7188;
          }
        }
      }
    }
  }

  .profile-preview__profile {
    padding-bottom: 59px;
    padding-top: 59px;

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 23px 0 64px 0;
    }


    @media (max-width: 767px) {
      .profile-user {
        grid-template-columns: auto;
        justify-content: center;
        align-items: center;
        gap: 27px;


        .profile-user__title, .profile-user__description {
          text-align: center;
        }

        .profile-user__title {
          font-weight: 400;
          font-size: 21px;
          line-height: 31px;
          color: #2F2C37;
          margin-bottom: 0;
        }

        .profile-user__description {
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          color: #8C8896;
        }


        .profile-user__edit {
          width: 90px;
          min-width: 90px;
          height: 90px;
          justify-self: center;

          .no-avatar,
          .profile-user__image {
            width: 90px !important;
            min-width: 90px !important;
            height: 90px !important;
          }
        }
      }
    }
  }

  .profile-preview__list {
    padding-bottom: 58px;
  }

}


