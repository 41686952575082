@import "src/common/assets/styles/mixin";

.order-index-hotel-info {
  display: grid;
  grid-template-columns: 125px 1fr;
  grid-gap: 15px;
  padding-top: 12px;

  @include mobileOnly() {
    grid-template-columns: 110px 1fr;
    padding-top: 0;
  }

  &__image {
    width: 100%;
    height: 106px;
    border-radius: 10px;
    background-color: var(--color-text-gray);
    background-position: center;
    background-size: cover;

    @include mobileOnly() {
      height: 110px;
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
  }

  &__texts-bottom-block {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      &:first-child {
        display: flex;
        align-items: center;
      }
    }

    .stars-rating {
      margin-right: 10px;
    }
  }

  &__hotel-name {
    font-size: 15px;
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }

  &__address, &__distance-text, &__reviews-text {
    font-size: 13px;
    line-height: 19px;
    color: var(--color-text-gray)
  }


}

