.switch-language {
  width: 587px;
  border-radius: var(--app-card-radius);
}

.radio-button-item {
  position: relative;
  height: 55px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: flex-start;
  column-gap: 16px;
  align-items: center;

  &.radio-button-item--disabled{
    opacity: .6;
    cursor: not-allowed;
  }

  .radio-button-item__text{
    display: flex;
    align-items: center;

    img{
      height: 25px;
      margin-right: 10px;
    }
  }


}