.message-user-item {
   background: #f4f2f6;
   border-radius: var(--app-card-radius);
   padding: 27px 14px 20px 9px;
   display: grid;
   grid-template-columns: 66px 1fr;
   grid-gap: 7px;
   align-items: center;

   .message-user-item__photos {
      position: relative;
      margin-bottom: 10px;

      .message-user-item__photo {
         width: 44px;
         border: 2px solid white;
         border-radius: 50%;

         overflow: hidden;

         img {

         }
      }
   }

   .message-user-item__text {
   }

   .message-user-item__header {
      margin-bottom: 3px;
   }

   .message-user-item__hotel-name {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: #2f2c37;
   }

   .message-user-item__owner {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: #8c8896;
   }

   .message-user-item__message {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: #2f2c37;
      margin-bottom: 9px;
   }

   .message-user-item__info {
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      color: #8c8896;
   }
}

.message-user-item-photo-owner {
   position: absolute;

   right: 0;
   bottom: -30%;
   z-index: 2;

   img {
   }
}
.message-user-item-photo-client {

}
