@import "src/common/assets/styles/mixin";

.container {
  max-width: calc(1280px + 30px); // 30 padding
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;

  @media all and (max-width: 768px) {
    padding: 0 24px;
  }
}
