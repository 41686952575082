.field-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.field-counter--is-first {
    .field-counter__minus {
      opacity: .5;
    }
  }

  &.field-counter--is-last {
    .field-counter__plus {
      opacity: .5;
    }
  }

  &.field-counter--primary {
    width: 100px;

    & input {
      font-style: normal;
      font-size: 17px;
      line-height: 25px;
      color: var(--color-text-gray);
    }
  }

  &.field-counter--secondary {
    //border: 1px solid #F4F2F6;
    border-radius: 4px;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    width: 146px;
    height: 32px;
    display: flex;
    align-items: center;

    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 29px;
      border-radius: 4px;
    }

    & input {
      width: 25px;
      height: 29px;
      color: #2F2C37;
      font-style: normal;
      font-size: 13px;
      line-height: 19px;
    }
  }

  button, input {
    height: var(--app-form-height-small);
  }

  button {
    width: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input {
    width: 100%;
    text-align: center;
    user-select: none;
  }

  .field-counter__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .field-counter__text {
    margin-right: 10px;
  }
}
