@import "../../../../../assets/styles/mixin";

.hotel-reviews {
  padding-top: 10px;
  padding-bottom: 50px;
  max-width: 1020px;

  @include mobileOnly() {
    padding-bottom: 0;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 62px;
    column-gap: 119px;

    @include mobileOnly() {
      display: block;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    color: #2F2C37;
    padding-bottom: 44px;
  }

  &__button-show-all {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: var(--color-primary-red);
    background-color: white;
    cursor: pointer;
    margin-left: 12px;
  }

  &__image-list {
    display: flex;
    align-items: center;
    margin-left: -16px;
    margin-top: 45px;

    img {
      background-color: var(--color-image-background);
    }

    @include mobileOnly() {
      margin-top: 0;
    }
  }

  &__image-list-item {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid #FFFFFF;

    &:nth-child(1) {
      position: relative;
      left: 16px;
    }

    &:nth-child(2) {
      position: relative;
      left: 8px;
    }

    &:nth-child(3) {
      z-index: 3;
    }
  }

  .show-more-button {
    margin-bottom: 0;
  }
}
