.profile-links {
   display: flex;
   align-items: center;
   cursor: pointer;

   picture,
   img {
      min-width: 36px;
      max-width: 36px;
      height: 36px;
      min-height: 36px;
      border-radius: 50%;
   }

   ul {
      margin-left: 10px;
      max-width: 200px;

      li {
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         //display: inline-block;
         min-width: 1px;
         &:first-child {
            margin-bottom: 3px;
         }

         font-weight: 400;
         font-size: 14px;
         line-height: 17px;
      }
   }
}

.profile-links-wrapper {
   position: relative;
}

.profile-dropdown {
   top: calc(100% + 9px);
   right: 0;
   position: absolute;
   box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
   border-radius: var(--app-card-radius);
   overflow: hidden;
   min-width: 255px;
   z-index: 99;
}

.profile-dropdown__list {
   padding: 8px 0;
   background-color: white;

   li {
      cursor: pointer;

      &.profile-dropdown-item--active {
         span {
            color: #e05233;
         }
      }
   }
}
