.hotels-no-data {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 50px;

  img {
    margin-bottom: 45px;
  }

  .app-text--variant-B400 {
    display: flex;
    margin-top: 15px;
  }
}
