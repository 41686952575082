@import "src/common/assets/styles/mixin";

.reservation-edit-modal {
  width: 540px;

  .spinner-container {
    border-radius: 12px;
  }

  .modal-layout-primary {
    min-height: 700px;

    @media (max-height: 800px) {
      min-height: 90vh;
    }
  }

  &__footer {
    .app-button {
      width: 100%
    }
  }

  .hotel-rooms-custom-date-pickers__label {
    font-size: 13px;
    font-weight: 200;
    color: var(--color-text-gray)
  }

  .mobile-modal-calendar__dates-title {
    display: none;
  }

  .react-datepicker-popper {
    padding-top: 0;
    margin-top: -15px;
  }

  .hotel-rooms-custom-date-pickers .hotel-rooms-custom-date-pickers__item:first-child > div {
    border: none;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
      right: -11px;
      width: 22px;
      top: 0;
      bottom: -20px;
    }
  }

  .hotel-rooms-custom-date-pickers .field-input {
    position: relative;

    //&:after {
    //  right: 0;
    //  top: 0;
    //  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 10.3171L5.65081 15.7592C5.23148 16.1187 4.60018 16.0701 4.24076 15.6508C3.88134 15.2314 3.9299 14.6001 4.34923 14.2407L11.3492 8.24072C11.7237 7.91973 12.2763 7.91973 12.6508 8.24072L19.6508 14.2407C20.0701 14.6001 20.1187 15.2314 19.7593 15.6508C19.3999 16.0701 18.7686 16.1187 18.3492 15.7592L12 10.3171Z' fill='%23C5C6CF'/%3E%3C/svg%3E");
    //  position: absolute;
    //}
  }
}
