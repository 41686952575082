@import "src/common/assets/styles/mixin";

.hotel-detail-user-actions {
  display: flex;

  .app-button {
    padding-left: 16px;
    padding-right: 16px;
    letter-spacing: normal;

    svg {
      fill: #C5C6CF;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__right-section {
    display: flex;
  }

  @include mobileOnly() {
    justify-content: space-between;
    position: absolute;
    left: -8px;
    right: 6px;
    top: var(--app-blocks-padding);
    //background:  #fff;
    background: transparent;
    padding: 0 var(--app-blocks-padding);
    z-index: 101;

    &--scrolled {
      //border-bottom: 1px solid #EBEBEB;
    }



    .app-button {
      background-color: #fff;
      padding-left: 0;
      padding-right: 0;
      font-size: 0;
      box-shadow: 0 10px 30px rgba(#8C8896, 0.13);
      min-width: 0;
      width: 34px;
      height: 34px;
      min-height: 34px;
      margin-left: 16px;
      margin-top: 5px;
      border-radius: 8px;

      .app-button__icon-wrap {
        margin: 0;

        svg {
          fill: #6D7188;
        }
      }
    }
  }
}
