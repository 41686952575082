.stars-rating {
  display: flex;
  align-items: center;

  img {
    //mask-type: alpha;
    position: relative;
    top: -1px;
  }

  &__stars-text {
    margin-left: 4px;
    align-self: flex-end;
    font-size: 13px;
    line-height: 19px;
    color: var(--color-text-black);
  }
}
