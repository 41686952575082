.order-success-payment-data__total-price-block {
  padding: 16px 24px;
  background: rgba(236, 249, 245, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__label-text {
    font-size: 17px;
  }

  &__price-text {
    font-size: 27px;
    font-weight: 600;
  }
}
