@import "src/common/assets/styles/mixin";

.hotels-list-page {
  display: flex;
  flex-direction: column;

  .page__content {
    display: flex;
    flex-direction: column;
  }

  &__list-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .page__header {
    transition: 0.3s all linear;
    top: 0;

    &.scrolled {
      opacity: 0.5;
    }
  }

  .hotels-list-header {
    transition: 0.3s top linear;
  }

  .header-mobile__search-button {
    padding-bottom: 0;
  }

  .container {
    padding: 0 24px;
  }

  @include mobileOnly() {
    &:not(.page--map) {
      .page__header {
        .header-mobile {
          //background-color: var(--color-content-alternative-background);
        }
      }

      .hotels-list-header {
        //background-color: var(--color-content-alternative-background);
      }

      .page__content {
        //background-color: var(--color-content-alternative-background);
      }
    }
  }

  &.scrolled-down {
    .page__header {
      top: calc(-1 * var(--header-height));
    }

    .hotels-list-header {
      top: 0;

      @include mobileOnly() {
        z-index: calc(var(--app-index-header) - 1);
      }
    }

    &.header-with-shadow {
      .hotels-list-header {
        z-index: calc(var(--app-index-header) + 1);
      }
    }
  }

  &.header-with-shadow {
    .hotels-list-header {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      @include mobileOnly() {
        z-index: calc(var(--app-index-header) - 1);
      }
    }

    .page__header {
      @include tabletAndUpper() {
        box-shadow: 0 0 0 1px rgba(#C4C4C4, 0.33);
      }
    }
  }
}
