.hotel-amenities-modal {
  .modal-layout-primary {
    width: 730px;

    @media (max-width: 750px) {
      max-width: 100%;
    }
  }

  &__list {
    margin-bottom: 16px;
  }

  &__list-header {
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    min-height: 56px;
    display: flex;
    align-items: center;
    color: var(--color-text-black);
    margin-top: 10px;
  }

  &__list-item {
    font-weight: 500;
    min-height: 56px;
    padding: 16px 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 22px;
    color: var(--color-text-black);

    border-bottom: 1px solid #F4F2F6;

    &:last-child {
      border-bottom: none;
    }
  }

  &__list {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}
