.modal-favorite-edit{
  width: 398px;
  border-radius: var(--app-card-radius);

  form{
    height: 100%;
  }

  .modal-favorite-edit__body{
    //min-height: 150px;
    padding-top: 28px;
    padding-bottom: 15px;
  }
}