@import '../../../../../assets/styles/main';

.field-upload {
  //border: 1px solid #dfe1e5;
  min-height: 163px;
  display: flex;
  flex-direction: column;

  .field-upload-body {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
    gap: 28px;
    padding-bottom: 0;
    padding-top: 20px;
    padding-right: 20px;
  }

  .field-upload-body__info {
    padding-top: 3px;

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #3d3a45;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .field-upload-body__avatar {
    margin-left: 14px;
  }

  .field-upload-body__image {
    width: 128px;
    height: 128px;
    min-width: 128px;
    object-fit:cover;
    //cursor: crosshair;

    mask-image: radial-gradient(circle at 50% 50%, black 60%, rgba(0, 0, 0, 0.3) 60%);
  }

  .field-upload-body__upload {
    cursor: pointer;
    margin-top: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #5ecbaa;
    background-color: white;
  }

  &.isError {
    border: 1px solid #ff564e;
    border-radius: var(--app-card-radius);
  }


  img {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
  }
}
