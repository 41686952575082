.auth-links {
  display: flex;
  align-items: center;

  .auth-links__user {
    background-color: #5ECBAA;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > ul {
    margin-left: 10px;
    margin-right: 5px;
    //min-width: 95px;

    > li {
      &:first-child {
        margin-bottom: 3px;
      }

      a {
        //font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
