
* {
  box-sizing: border-box;
}

body, html {
  padding: 0;
  margin: 0;
}

input, button, select {
  appearance: none;
  border: none;
  outline: none;
  box-shadow: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// забираю іконки з інпутів
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}


/* IE11 */
select::-ms-expand {
  display: none;
}

/* firefox забирає стрілки з інпутів */
input[type=number] {
  -moz-appearance: textfield !important;
}

//
//
//input,
//select {
//  &:focus {
//    outline: none;
//    border: 2px solid #4f4f4f;
//    box-shadow: none; //inset 0 0 0 2px $secondary-color-alternative;
//    //border: $form-border-focus;
//  }
//}
//button {
//  &:focus {
//    outline: none;
//    //border: none;
//    box-shadow: none;
//  }
//}
