.code-input {
   &.isError {
      label {
         color: var(--app-input-error-color);
      }
   }

   &.isFocus {
      &:not(.isError) {
         label {
            color: var(--app-input-focus-color);
         }
      }
   }

   .code-input__wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
   }
   .code-input__item {
      input {
         text-align: center;
      }
   }
}

.code-label {
   pointer-events: none;
   padding-left: 0.125rem;
   z-index: 1;
   color: var(--app-input-label-color);
   font-weight: 500;
   line-height: 22px;
   transition: all 0.28s ease;
   font-size: 0.8rem;
}
