@import "src/common/assets/styles/mixin";

.footer {
  padding: 80px 0;
  background: var(--footer-background);
  position: relative;

  color: var(--footer-texts-color);
  font-size: 13px;
  z-index: 101;

  @include mobileOnly() {
    padding-top: var(--app-blocks-padding);
    padding-bottom: calc(var(--mobile-footer-height) + 20px);
  }

  a {
    color: var(--footer-texts-color);

    &:hover {
      color: var(--app-color-primary);
    }
  }

  &__content {
    display: flex;

    @include mobileOnly() {
      display: block;
    }
  }

  &__content-block {
    &--list {
      flex: 1;

      ul {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;

        li {
          padding-bottom: 8px;

          @include mobileOnly() {
            padding-bottom: 12px;
          }
        }

        a {
          font-size: 13px;
          font-weight: 400;
          //opacity: 0.6;
        }

        @media all and (max-width: 1000px) {
          grid-template-columns: 1fr 1fr;
        }

        @include mobileOnly() {
          grid-template-columns: 1fr 1fr;

          a {
            display: block;
          }
        }
      }
    }

    &--contacts {
      max-width: 250px;
      margin-left: auto;


      @include mobileOnly() {
        max-width: 100%;
        margin-top: var(--app-blocks-padding);
      }

      &__contacts-text {
        font-size: 15px;
        line-height: 17px;

        &__phone-link {
          margin: 5px 0;
          font-weight: 600;
          display: block;
        }
      }

      &__rights-text {
        margin: 20px 0 15px;
        font-size: 13px;
        line-height: 21px;
        //opacity: 0.45;
      }
    }

    &__text {
      color: var(--footer-caption-texts-color);
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;

      display: flex;
      margin-bottom: 16px;
    }
  }

  &__register-link-container {
    padding: 0 0 8px;

    .app-button {
      border-color: rgba(94, 203, 170, 0.33);
    }
  }

  &__extranet-link{
    .app-button {
      color: white;
      border-color: rgba(255, 255, 255, 0.13);

      &:hover{
        border-color: white;
        color: white;
      }
    }
  }

  &.footer-light{

    .footer__extranet-link{
      .app-button {
        color: rgba(61, 58, 69, 1) ;
        border-color: #c5c6cf;

        &:hover{
          border-color: rgb(157, 158, 166);
          color: rgba(61, 58, 69, 1);
        }
      }
    }
  }

}

.footer-site-logo {
  path{
  //stroke: var(--footer-site-logo-stroke);
  fill: var(--footer-site-logo-stroke);

  }
}
