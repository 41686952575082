.hotels-sorting-dropdown {
  padding-left: 24px;
  position: absolute;
  background-color: #fff;
  border-radius: 12px;
  right: 0;
  top: calc(100% + 10px);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  min-width: 350px;
  z-index: 101;
}
