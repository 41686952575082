.custom-carousel {
  position: relative;

  &.disabled {
    height: 100%;

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: "";
      display: block;
    }
  }
}

.custom-carousel-out {

  .slick-arrow {
    &:before {
      display: none;
    }
  }

  .carousel--secondary {
    .slick-slider {
      .slick-arrow {
        opacity: 0;
      }

      &:hover {
        .slick-arrow {
          opacity: 1;
        }
      }
    }

  }
}
