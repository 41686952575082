.contacts-header{
  background-size: cover;
  min-height: 300px;
  padding-top: 92px;

  @media (max-width: 768px) {
    background-image: none  !important;
    min-height: auto;
    padding-top: 0;
    margin-bottom: 24px;
  }

  &__breadcrumbs{
    margin-bottom: 6px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  h1{
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    color: #2F2C37;
    @media (max-width: 768px) {
      text-align: center;
      font-weight: 600;
      font-size: 27px;
      line-height: 40px;
      margin-bottom: 7px;
    }
  }


  h2{
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #8C8896;

    @media (max-width: 768px) {
      text-align: center;
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
    }

  }
}