.price-viewer {
  white-space: nowrap;

  &--old-price {
    color: var(--color-light-gray);
    text-decoration: line-through;
  }

  &--total-after-discount {
    color: var(--color-primary-red);
  }

  span {
    font-size: inherit;
    font-weight: inherit;
  }

  &__additional-text {
    font-size: 13px;
    color: var(--color-text-gray);
  }
}
