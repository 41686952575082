@import "src/common/assets/styles/mixin";

.hotels-list-outer {
  &--is-no-data {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &--is-loading {
    padding-top: 55px;

    @include mobileOnly() {
      padding-top: 35px;
    }
  }

  .hotels-no-data {
    padding-top: 130px;
  }
}

.hotels-list-more-hotels-texts-section {
  display: flex;
  flex-direction: column;
  padding-bottom: 26px;

  span {
    &:first-child {
      font-size: 27px;
      line-height: 40px;
      display: block;
      margin-bottom: 2px;
      color: #000;

      @include mobileOnly() {
        font-size: 21px;
        margin-bottom: 10px;
        font-weight: 500;
        line-height: normal;
      }
    }

    &:nth-child(2) {
      color: var(--color-text-black);
      font-size: 16px;
      font-weight: 400;

      @include mobileOnly() {
        font-size: 15px;
      }
    }
  }
}

.hotels-list {
  margin: 0 0 50px;
  padding: 0;
  list-style: none;
  display: grid;
  --columns-repeat: 4;
  //Using this rule, because slick carousel cannot calculate right width with "fr"
  //grid-template-columns: repeat(4, minmax(300px, min-content));
  grid-template-columns: repeat(var(--columns-repeat), 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 33px;
  width: 100%;

  &__item {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    @media (max-width: 650px) {
      margin-bottom: 32px;
    }
  }

  @media (max-width: 1175px) {
    grid-column-gap: 15px;
  }

  @media (max-width: 1060px) {
    //grid-template-columns: repeat(3, minmax(100px, max-content));
    --columns-repeat: 3;

  }

  @media (max-width: 900px) {
    --columns-repeat: 2;
  }

  @media (max-width: 650px) {
    display: block;
  }
}
