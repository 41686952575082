@import "src/common/assets/styles/mixin";

.hotel-list-item-top-info {
  margin: 8px 0;
  align-items: center;
  font-size: 13px;
  color: var(--color-text-gray);
  display: flex;

  &--full {
    grid-template-columns: auto auto 1fr;
    grid-gap: 10px;

    .hotel-list-item-top-info__distance {
      width: 100%;
      //text-align: right;
    }
  }

  &__stars {
    //margin-right: 8px;
    margin-left: -2px;
  }

  &__reviews {
    margin-right: 5px;
    white-space: nowrap;
    //padding-bottom: 2px;
  }

  &__distance {
    overflow: hidden;
    width: 100%;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include mobileOnly() {
      display: flex;
      justify-content: space-between;
    }
  }
}
