@import "src/common/assets/styles/mixin";

.hotel-detail-page {
  max-width: 1150px;

  .hotel-detail-rooms-list-wrapper {
    display: grid;
    grid-template-columns: auto 370px;

    &__rooms-wrapper {

      padding-right: 30px;
    }

    &.booking-state-cart, &.booking-state-both {
      //grid-template-columns: auto 280px;
    }
  }

  @include mobileOnly() {
    //padding-top: 44px;
    padding-top: var(--app-blocks-padding);
    padding-bottom: 5px;
  }
}


