
.breadcrumbs {
  display: flex;
  margin-bottom: 6px;
  list-style: none;

  .breadcrumbs__item {
    &:after {
      content: "/";
      font-weight: 500;
      color:#6B778C;
      font-size: 12px;
      margin-left: 8px;
      margin-right: 8px;
    }

    &:last-child {
      cursor: default;

      .breadcrumbs__item-text {
        color: #6B778C;
      }

      &:after {
        content: "";
      }
    }

    .breadcrumbs__item-text {
      white-space: nowrap;
    }

    a {
      color: #505F79;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      border-bottom: 1px solid #505F79;

      &:hover {
        color: #505F79;
      }
    }
  }

  .breadcrumbs__item-text {
    color: #505F79;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    font-weight: 500;
  }

}
