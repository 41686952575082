.modal-favorite-select-group {
   width: 567px;
   border-radius: var(--app-card-radius);

   .modal-favorite-select-group__list {
      list-style: none;

      > li {
         margin-bottom: 16px;
         cursor: pointer;
      }
   }
}

.favorite-select-item {
   display: grid;
   grid-template-columns: 64px 1fr;
   gap: 16px;
   align-items: center;
   .favorite-select-item__image {
      border-radius: var(--app-card-radius);
   }

   .favorite-select-item__title {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #000000;
   }

   .favorite-select-item__description {
      font-size: 13px;
      line-height: 19px;
      color: #8c8896;
   }
}
