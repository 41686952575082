.hotel-room-item {
  display: grid;
  grid-template-columns: 204px 1fr;
  grid-gap: 20px;
  border-bottom: 1px solid #F4F2F6;
  padding-bottom: 25px;
  margin-bottom: 25px;
  max-width: 100%;

  &:last-child {
    border-bottom: none;
  }

  &__image {
    width: 100%;
  }

  &__image-block {
    position: relative;
    height: 204px;
    flex: 0 0 204px;
    background-color: #f4f4f4;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    a {
      display: block;
    }

    .carousel .slick-slider .slick-dots, .carousel.carousel--secondary .slick-slider .slick-dots {
      bottom: 10px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__top-row {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    display: flex;
    align-items: center;
    position: relative;
    color: var(--color-text-black);
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    padding-right: 10px;
    padding-bottom: 2px;
  }

  &__room-size {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: right;
    color: var(--color-text-gray);
    position: relative;
    left: -1px;
    top: -1px;
    white-space: nowrap;
  }

  &__info-row {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: var(--color-text-gray);
    position: relative;

    > div {
      &:first-child {
        flex: 1;
        padding-top: 6px;
        display: flex;
        justify-content: space-between;
      }

      &:last-child {
        padding-left: 20px;
      }
    }
  }

  &__amenities-text {
    display: flex;
    list-style: none;
    flex: 1;
    top: 4px;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  &__amenities-more {
    white-space: nowrap;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 20%);
    align-items: center;
    padding-left: 10px;

    button {
      background: transparent;
      border: none;
      font-size: 13px;
      color: var(--color-text-gray);

      &:hover {
        cursor: pointer;
        color: var(--color-primary-green);
      }
    }
  }

  &__prices {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }
}
