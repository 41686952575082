.app-checkbox {
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;

  &__label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid var(--color-icon-light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
      border-color: var(--color-primary-blue);
    }
  }

  input {
    &:checked {
      + label {
        border-color: var(--color-primary-blue);
        background-color: var(--color-primary-blue);

        &:after {
          content: url('data:image/svg+xml; utf8, <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.11801 8.36307L1.16428 4.43461C0.949516 4.14898 0.543869 4.09153 0.258239 4.30629C-0.0273915 4.52105 -0.0848435 4.92669 0.129916 5.21232L3.53549 9.74173C3.77332 10.0581 4.23688 10.0887 4.51423 9.80634L12.0081 1.91498C12.2585 1.66006 12.2549 1.25038 12 0.999939C11.7451 0.749493 11.3354 0.753119 11.085 1.00804L4.11801 8.36307Z" fill="white"/></svg>');
          position: relative;
          top: 0px;
          left: 1px;
        }
      }
    }
  }
}
