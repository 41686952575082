@import "src/common/assets/styles/mixin";

.hotel-book-section {


  @include mobileOnly() {
    text-align: center;
    font-size: 17px;

    button {
      color: #000;
    }
  }
}
