@import "../../../../../assets/styles/mixin";

.hotel-description {
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;

  @include mobileOnly() {
    padding-bottom: 0;
  }

  &__header-info-row {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-weight: 500;
    font-size: 27px;
    line-height: 40px;
    color: #2F2C37;
    margin-bottom: 7px;
    padding-top: 20px;

    @include mobileOnly() {
      font-size: 21px;
      line-height: 31px;
      font-weight: 600;
      padding-top: 0;
      //padding-top: 10px;
    }
  }

  &__block-stars-address {
    display: flex;
    justify-content: left;


  }

  &__top-row-stars-wrapper {
    margin-right: 19px;
  }

  &__info-texts {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      max-width: 20px;
    }

    @include mobileOnly() {
      margin-bottom: 10px;
    }
  }

  &__accommodation-content {
    display: flex;
  }

  &__info-text {
    color: var(--app-input-label-color);
    font-size: 13px;
    line-height: 19px;
    margin-right: 12px;

    align-self: center;
    position: relative;

    &--with-dot {
      @include mobileOnly() {
        &:before {
          content: '•';
          position: relative;
          top: -1px;
          margin-right: 5px;
        }
      }
    }
  }

  &__top-row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;

    > div {
      align-items: center;
      display: flex;
    }
  }

  &__top-row-texts-wrapper {
    width: 100%;
  }

  &__top-row-texts {
    padding-left: 10px;
    color: var(--color-text-gray);
    font-size: 13px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include mobileOnly() {
      padding-left: 0;
    }
  }

  &__description-and-form-row {
    display: grid;
    grid-template-columns: auto 370px;
    gap: 50px;
    font-size: 15px;
    line-height: 22px;
    color: var(--color-text-black);
    position: relative;

    @media (max-width: 767px) {
      grid-template-columns: auto;
      gap: 15px;
    }
  }

  &__description-wrap {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(#C5C6CF, 0.5);
  }

  &__description {
    overflow: hidden;
    position: absolute;
    max-height: 100%;

    @include mobileOnly() {
      position: relative;
      overflow: hidden;
      line-clamp: 4;

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    &.show-more {
      position: static;
      padding-bottom: 30px;
    }
  }

  &__show-more-wrapper {
    height: 100px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 40%);
    position: relative;
    margin-top: auto;
    padding-bottom: 20px;
    display: flex;
    align-items: flex-end;
    z-index: 100;

    @include mobileOnly() {
      height: auto;
      //padding: 10px 0 12px;
      left: 0;
      right: 0;
    }

    &.is-open-more {
      height: 50px;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: #2F2C37;
    padding-top: 30px;

  }
}

.modal .modal__content.hotel-description-about-modal {
  max-width: 800px;

  .modal-layout-primary__body {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.hotel-description-about-modal {
  &__title {
    font-size: 27px;
    margin-bottom: 20px;
    line-height: 35px;
  }

  &__text {
    font-size: 15px;
    line-height: 22px;
  }
}

.hotel-description-about-modal__text, .hotel-description__description {
  margin-bottom: 15px;

  p {
    margin-bottom: 15px;
  }

  ul {
    padding-left: 15px;
    margin-bottom: 15px;
  }
}
