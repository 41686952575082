.favorite {
  padding-bottom: 50px;

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 30px;

    @media (max-width: 1070px) {
      & {
        grid-template-columns: 1fr 1fr
      }
    }
    @media (max-width: 850px) {
      & {
        grid-template-columns: 1fr;

      }
    }
  }
  &__title{
    margin-bottom: 16px;
  }
}