.query-not-found{
  padding: 24px 10px;

  @media (max-width: 767px){
  padding: 24px 0;

  }
  h3{
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #3D3A45;
    margin-bottom: 21px;
    text-align: left;
    max-width: 400px;
  }

  p{
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #8C8896;
    max-width: 400px;

  }
}