
.hotel-registration-success {
  display: grid;
  height: 100%;

  p {
    font-weight: 400;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 22px;
  }

  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: var(--app-color-primary)
  }
}

.hotel-registration-contract{
  width: 800px;
}

.hotel-registration-init {
  display: flex;
  flex-direction: column;

  form {
    display: grid;
    height: 100%;
  }

  &__radio {

  }

  &__label {
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    color: #2F2C37;
    margin-bottom: 17px;
    display: inline-block;
  }

  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__submit {
    align-self: flex-end;
    margin-top: 25px;
    width: 100%;
  }

  &__agree{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    line-height: 20px;
    margin-top: -25px;

    a{
      color: rgba(108, 94, 203, 1);
    }
    .field-control{
      margin-bottom: 0;
    }

    span{
      line-height: 24px;
    }
  }
  &__feedback{
    margin-top: 25px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
    max-width: 380px;
    color: rgba(140, 136, 150, 1);
  }

  &__modal{
    position: fixed;
    top:0;
    right: 0;
    width: 872px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 2;
  }

}


.hotel-search-mui {

  &__label {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: var(--app-input-label-color);
  }

  .custom-select {

    &__menu {
      //box-shadow: none;
    }

    &__control {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid var(--app-input-label-color);

      &--is-focused {
        border-bottom: 1px solid var(--app-input-focus-color);
        box-shadow: none;
      }
    }

    &__placeholder {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #24253D;
    }


  }
}


.resend-code-block {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #8C8896;

  span {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #6D5ECB;
    cursor: pointer;
  }
}


.registration-select-hotel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h4{
    color: #3d3a45;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
  }
  h5{
    color: #8c8896;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 40px;
  }
}

.available-hotel-option{
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  padding: 16px;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: var(--app-card-radius);
  margin-bottom: 12px;

  .form-radio {
    height: 19px;
    margin-bottom: 0;
  }

  &.isError{
    border: 1px solid var(--app-input-error-color);
  }

  &.isActive{
    border: 1px solid var(--app-input-focus-color);
  }

  .available-hotel-option__main {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 8px;


    img {
      width: 36px;
      height: 36px;
      min-width: 36px;
      border-radius: 6px;
      object-fit: cover;
    }

    span {
      color: #24253d;
      font-weight: 500;
      line-height: 22px;
      font-size: 15px;
      position: relative;
    }
  }

}
