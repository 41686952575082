.app-text {
  color: var(--color-text-black);

  &--variant-caption {
    font-size: 13px;
    color: var(--color-icon-gray);
  }

  &--variant-gray {
    font-size: 13px;
    line-height: 22px;
    color: var(--color-text-gray);
    font-weight: normal;
  }

  &--variant-B400 {
    font-size: 15px;
    line-height: 22px;
  }

  &--variant-B500 {
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
  }

  &--variant-H100 {
    font-size: 21px;
    line-height: 31px;
  }

  &--variant-H200 {
    font-size: 27px;
    line-height: 40px;
  }

  &--variant-H300 {
    font-size: 32px;
    line-height: 48px;
    //color: #000000;
  }

  &--variant-C400 {
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    color: var(--color-text-gray);
  }

  &--weight-400 {
    font-weight: 400;
  }

  &--weight-500 {
    font-weight: 500;
  }

  &--weight-600 {
    font-weight: 600;
  }
}
