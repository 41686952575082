@import "src/common/assets/styles/mixin";

.clickable-block {
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  align-items: center;
  border-top: 1px solid rgba(#C5C6CF, 0.33);

  @include mobileOnly() {
    padding: 12px 0;
  }

  &:hover {
    opacity: 0.7;
  }

  > div {
    flex: 1;
    padding-right: 20px;
  }

  &__title {
    font-size: 15px;
    font-weight: 500;

    @include mobileOnly() {
      font-size: 17px;
      font-weight: 600;
    }
  }

  &__description {
    color: var(--color-text-gray);
    font-size: 13px;
    margin-top: 8px;
  }
}
