@import "src/common/assets/styles/mixin";

.hotels-list-header {
  position: sticky;
  top: var(--header-height);
  background-color: #fff;
  z-index: 1;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    @include mobileOnly() {
      padding: 0;
      height: auto;
    }
  }

  //@media (max-width: 840px) {
  //  & {
  //    justify-content: center;
  //  }
  //}

  &__buttons {
    display: flex;
    position: relative;

    @include mobileOnly() {
      width: 100%;
    }
  }

  &__text {
    //font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 40px;
    color: #000000;

    @media all and (min-width: 767.98px) and (max-width: 900px) {
      font-size: 21px;
    }

    @media (max-width: 767.98px) {
      display: none;
    }
  }
}
