.room-item-price {
  display: flex;
  font-size: 15px;
  line-height: 19px;
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F4F2F6;

  &--disabled {
    color: var(--color-icon-light-gray);

    .room-item-price__meal-type {
      svg {
        fill: var(--color-icon-light-gray);

        &.RoomPriceMealIconFB {
          stroke: var(--color-icon-light-gray);
        }
      }
    }
  }

  &:last-child:not(:first-child) {
    border-bottom: none;
  }

  &__meal-type {
    display: flex;
    align-items: center;


    svg {
      display: block;
      margin-right: 10px;
      fill: var(--color-icon-light-gray);

      &.RoomPriceMealIconFB {
        fill: none;
        stroke: var(--color-icon-light-gray);
      }
    }
  }

  &__nights-persons-icons {
    padding-top: 3px;
    display: flex;
    align-items: center;
    min-height: 22px;

    svg {
      margin-right: 2px;
    }

    span {
      color: #C5C6CF;
      display: inline-block;
      font-weight: 500;
      width: 10px;
    }
  }

  &__right-section {
    display: flex;
    align-items: center;
  }

  &__prices {
    display: flex;
    flex-direction: column;
    text-align: right;

    &--with-select {
      padding-right: 40px;
    }
  }

  &__actions {
    min-width: 130px;
  }

  .price-viewer {
    font-size: 15px;
    font-weight: 600;
  }

  .price-viewer--total-after-discount {
    font-weight: 500;
  }
}
