.conversations-navigation {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-column-gap: 10px;
   padding: 4px;
   background-color: #fbfbfb;
   border-radius: var(--app-card-radius);

   button {
     min-width: 115px;
      width: 100%;
   }
}
