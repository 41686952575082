
.sign-up {
  display: flex;
  flex-direction: column;

  button {
    //width: 100%;
  }

  form{
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;

    .sign-up__submit{
      margin-left: auto;

      &.sign-up__full{
        width: 100%;
      }
    }
  }


  .sign-up__resend-link {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    color: #8C8896;

    >span{
      color: var(--app-color-primary);
      cursor: pointer;
    }
  }




}