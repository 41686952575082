@import "src/common/assets/styles/mixin";

.hotel-detail-phone-order {
  flex: 0 0 370px;
  top: 100px;
  margin-bottom: 25px;
  position: sticky;

  @include mobileOnly() {
    margin-bottom: 0;
  }

  &__block {
    //padding: 22px 26px 26px 26px;
    padding: 15px 15px 20px 15px;
    border: 1px solid rgba(197, 198, 207, 0.5);
    border-radius: var(--app-card-radius);
    margin-bottom: 20px;

    @include tabletAndUpper() {
      &:hover {
        box-shadow: 0 4px 23px rgba(0, 0, 0, 0.08);
      }
    }

    @include mobileOnly() {
      margin-bottom: 0;
      border: none;
      width: 100%;
      padding: 0;
    }
  }

  &__feedback-button {
    width: 100%;
    //margin-top: 15px;
  }

  &.from-modal {
    margin-bottom: 0;
  }

  &.from-modal & {
    &__block {
      padding: 0;
      border: none;
      margin-bottom: 0;

      &:hover {
        box-shadow: none;
      }
    }
  }
}
