.favorite-detail{
  padding-top: 25px;

  &__list{
    width: 100%;
    border: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
    @media (max-width: 1375px) {
      & {
        grid-template-columns: 1fr 1fr 1fr
      }
    }
    @media (max-width: 1070px) {
      & {
        grid-template-columns: 1fr 1fr
      }
    }
    @media (max-width: 850px) {
      & {
        grid-template-columns: 1fr;

      }
    }
  }
}