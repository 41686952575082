.mobile-room-detail-prices {
  display: flex;
  padding-top: 24px;
  flex: 1;
  flex-direction: column;

  &.disabled {
    .price-viewer__price, .app-text {
      color: var(--color-icon-light-gray);
    }
  }

  &__title {
    display: flex;
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    margin-top: 15px;
    //color: var(--color-text-gray)
  }

  &__prices {
    padding-bottom: 16px;
  }

  &__price-row {
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 35px 1fr 1fr;
    padding: 10px 0;
    border-bottom: 1px solid rgba(#C5C6CF, 0.3);

    .price-viewer__price {
      font-size: 15px;
      line-height: 22px;
      font-weight: 500;
    }

    &:last-child {
      border-bottom: none;
    }

    > div:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__meal-icon-wrap {
    svg {
      fill: var(--color-icon-light-gray);
    }
  }

  &__price-label {
    font-size: 11px;
    font-weight: 500;
    display: block;
    margin-bottom: 2px;
  }

  &__choose-button {
    width: 100%;
    margin-top: auto;
    //margin-top: 16px;
  }
}
