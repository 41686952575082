.mobile-room-detail-book-section-texts {
  .app-text {
    display: block;
  }

  &__title {
    display: block;
    margin-bottom: 6px;
    font-size: 17px;
    font-weight: 500;
  }
}
