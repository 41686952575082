.profile-navigation {

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 58px;
    list-style: none;

    @media (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-row-gap: 0;
    }
  }

  .profile-navigation__logout{
    &.profile-navigation-item{
     .profile-navigation-item__icon-right{
          display: none;
      }


      svg {
        path {
          fill: var(--color-primary-red);
        }
      }

      .profile-navigation-item__title{
        color: var(--color-primary-red);
      }
    }

  }
}