@import "src/common/assets/styles/mixin";

.order-page {
  overflow: hidden;

  &--mobile {
    //padding-top: calc(25px + var(--app-blocks-padding));
  }

  &__title {
    font-size: 32px;
    line-height: 48px;
    font-weight: 500;
  }

  &__container {
    max-width: 1120px;
    padding: 45px 0;

    @include mobileOnly() {
      padding: 0 var(--app-blocks-padding) 45px;
    }
  }

  &__container-loading {
    flex: 1;
    min-height: 50vh;

    @include mobileOnly() {
      min-height: 100vh;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    padding: 12px 0;

    > div {
      flex: 1;

      &:first-child {
        padding-right: 140px;

        @media all and (max-width: 1150px) {
          padding-right: var(--app-blocks-padding);
        }
      }
    }
  }

  &__order-info {
    padding-top: var(--app-blocks-padding);
  }

  &__order-info-top-line {
    margin-bottom: 30px;
  }

  &__order-info-clickable-blocks {
    margin-top: 20px;

    @include mobileOnly() {
      margin-bottom: 0;

      .clickable-block {
        &:first-child {
          border-top: none;
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &__summary {
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    padding: var(--app-blocks-padding);
  }

  &__mobile-header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 45px;
    padding-top: 10px;
    margin-bottom: 30px;
    background-color: #fff;
    z-index: 500;

    h1 {
      display: block;
      flex: 1;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      padding: 0 30px;
    }
  }

  &__mobile-header-arrow {
    position: absolute;
    left: 0;
    top: 16px;
  }

  &__mobile-block-separator {
    height: 8px;
    background-color: var(--common-borders-color);
    position: relative;
    margin: var(--app-blocks-padding) -24px;
  }

  &__reservations-mobile-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    border-top: 1px solid var(--common-borders-color);
    font-size: 15px;
    font-weight: 500;
  }
}
