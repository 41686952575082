.next-trips {
  margin-top: 46px;
  margin-bottom: 46px;

  @media (max-width: 850px) {
    margin-top: 0;
  }

  &__title {
    margin-top: 40px;
    font-weight: 500;
    font-size: 27px;
    color: #2F2C37;
    margin-bottom: 20px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr
    }

    @media (max-width: 580px) {
      grid-template-columns: 1fr
    }
  }
}
