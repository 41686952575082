

.modal-favorite-share{
  width: 567px;
  border-radius: var(--app-card-radius);
}

.share-object{
  display: grid;
  grid-template-columns: 64px 1fr;
  gap: 15px;
  align-items: center;
  margin-bottom: 24px;

  .share-object__image{
    overflow: hidden;
    border-radius: var(--app-card-radius);
  }
  .share-object__text{
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #000;
  }
}


.share-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  list-style: none;
  //padding: 24px;

  li{
    display: flex;
    align-items: center;
    border-radius: var(--app-card-radius);
    border: 1px solid #F4F2F6;

    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #2F2C37;

    div{
      width: 35px;
    }

    button{
      cursor: pointer;
      font-weight: inherit;
      font-size: inherit;
      background-color: rgba(#fff, 0);
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      gap: 20px;
      padding: 23px 25px !important;
    }
  }
}