.mobile-room-list-item {
  background-color: #fff;
  //background-color: #f8f8f8;
  border-radius: 8px;
  //padding: 15px;


  .custom-carousel-out {
    height: 165px;
    background-color: var(--color-image-background);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;

    .custom-carousel {
      height: 165px;
    }

    .gallery-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }

  &__title {
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__price {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .price-viewer__price {
      font-size: 17px;
      line-height: 25px;
    }

    &.disabled {
      .price-viewer__price, .app-text {
        color: var(--color-icon-light-gray);
      }
    }
  }

  &__price-with-info-text {
    display: flex;
    align-items: center;
  }

  &__footer {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .prices-list {
      display: flex;
      flex-direction: column;
    }
  }

  &__footer-prices-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      padding-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .no-rooms-message {
    + .mobile-room-list-item__footer {
      padding-top: 0;
    }
  }

  &__show-room-button {
    margin-top: 10px;
    width: 100%;
  }
}
