@import "src/common/assets/styles/mixin";

.order-payment-type-select {
  font-size: 15px;
  line-height: 21px;

  &__row {
    margin: 16px 0;
  }

  &__title {
    //color: var(--color-text-gray);
    font-weight: 400;
  }

  &__description {
    color: var(--color-text-gray);
    font-weight: 500;
  }

  @include mobileOnly() {
    margin-top: 16px;
  }
}
