@import "src/common/assets/styles/mixin";

.page {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .page__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: var(--app-index-header);
    //box-shadow: 0 5px 6px 0 rgb(34 60 80 / 5%);
    border-bottom: 1px solid rgba(#C4C4C4, 0.33);

    @include mobileOnly() {
      box-shadow: none;
      border: none;
    }
  }

  .page__content {
    flex: 1;

    @include mobileOnly() {
      //padding-bottom: var(--mobile-footer-height);
    }

  }

  &--hotel-detail {
    .container {
      max-width: 1150px;
    }

    @include mobileOnly() {
      .page__content {
        padding-bottom: 0;
      }

      .footer {
        padding-bottom: calc(var(--mobile-footer-height) + 100px);
      }
    }

    .page__header {
      position: static;
    }
  }

  //&--index-variant {
  //  .header {
  //
  //  }
  //}

}
