.contacts-block{
  h3{
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #181B20;
    margin-bottom: 6px;

    @media (max-width: 768px) {
      font-weight: 600;
      font-size: 21px;
      line-height: 31px;
    }

  }

  h5{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #3D3A45;
    margin-bottom: 46px;

    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
  }


  &__info{
    margin-bottom: 46px;
  }
  &__social{
    margin-bottom: 46px;

  }

}