
.should-auth-modal {
  width: 600px;

  h2 {
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    margin-bottom: 34px;
    text-align: center;
  }

  &__wrapper {
    padding: 24px 30px 34px 30px;

    @media (max-width: 768px) {
      padding: 20px 30px 34px 30px;
    }
  }

  &__navigation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
    padding: 0 10px 0 10px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    a{
      width: 100%;
    }

  }
}